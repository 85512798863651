@import "../../../styles/mixins/global.scss";

footer {
  background-color: #162033;
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 3 0;
  @include breakpoint("max-sm") {
    padding: 60px 0 30px 0;
  }
  .footerChild {
    padding: map-get($pixel, md) * 3 0 0 0;
    ul {
      list-style: none;
      @include flex-center;
      @include breakpoint("max-sm") {
        display: block;
      }
      @include breakpoint("max-sm") {
        li:last-child {
          padding-bottom: 0px;
        }
      }
      li,
      a {
        @include text-sm-bold;
        font-weight: 400;
        cursor: map-get($layout, pointer);
        color: #bbc3c9;
        letter-spacing: 0.02em;
        padding-right: map-get($pixel, md) * 4;
        @include breakpoint("max-sm") {
          padding: 0 0 map-get($pixel, md) * 2 0;
        }
      }
    }
  }
  .footerGrid {
    @include grid-layout;
    padding-bottom: map-get($pixel, md) * 6;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    grid-template-columns: 180px 1fr;
    gap: 0 76px;
    justify-content: space-between;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
      padding-bottom: 30px;
    }
    .footerGridItems {
      .footerSubGrid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1, 1fr);
          gap: 20px 0;
        }
        .footerSubGridItems {
          p {
            @include text-sm-bold;
            color: map-get($colors, white-color);
            letter-spacing: 0.02em;
            font-weight: 700;
            margin: 0 0 map-get($pixel, xl) + 1px 0;
            text-transform: uppercase;
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li,
            a {
              @include text-sm-bold;
              font-weight: 400;
              line-height: 21px;
              color: map-get($colors, white-color);
              padding-bottom: 1rem;
              cursor: map-get($layout, pointer);
            }
          }
        }
      }
      img {
        width: 180px;
      }
    }
  }
}
