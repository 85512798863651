@import '../../../styles/mixins/global.scss';

.LrcGroupProvideAlignment {
    padding: map-get($pixel, md ) * 10 0 map-get($pixel, md ) * 7 0;
    @include breakpoint("max-md") {
        padding: map-get($pixel, md ) * 10 0;
    }
    @include breakpoint("max-sm") {
        padding: 60px 0 ;
    }
    .titleText {
        h1 {
            @include heading-large;
            color: map-get($colors, black-3 );
            margin: 0;
            max-width: 800px;
            @include breakpoint("max-md") {
                max-width: 100%;
                @include heading-9;
            }
            @include breakpoint("max-sm") {
                @include heading-1;
            }
        }
    }
    .subTitle {
      
        div {
            display: flex;
            justify-content: flex-end;
            width: 100%;

        }
        p {
            max-width: 539px;
            @include text;
            color: map-get($colors, black-4 );
            margin: 0;
            line-height: map-get($pixel, md ) * 2 + 2px;
            @include breakpoint("max-md") {
                padding: 60px 0 0 0;
            }
        }
    }
}

.lrcGroupProvideBanner {
    img {
        width: 100%;
        height: 600px;
        object-fit: cover;   
        @include breakpoint("max-md") {
            height: 400px;
        }
    }
}

@include breakpoint("max-sm") {
    .imageLrcMobileView {
        padding: 0;
    }
}