@import "../../../styles/mixins/global.scss";

.familyforFundsSectionAlignment {
  padding: map-get($pixel, xl) * 8 0;
  @include breakpoint("max-sm") {
    padding: 80px 0 80px 0;
  }
  .pageTitle {
    padding-bottom: map-get($pixel, xl) * 3;
    @include breakpoint("max-sm") {
      display: grid;
      padding-bottom: 40px;
    }
    @include breakpoint("max-sm") {
      div:first-child {
        order: 2;
      }
      div:last-child {
        order: 3;
      }
    }
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      margin: 0 0 map-get($pixel, md) * 2 0;
      @include breakpoint("max-sm") {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 20px 0;
      }
    }
    p {
      @include text;
      color: map-get($colors, black-4);
      line-height: 22px;
      a {
        @include text;
        display: inline-block;
        padding-left: map-get($pixel, sm);
        font-weight: 600;
        padding: 0;
        color: map-get($colors, black-4);
        line-height: 22px;
        text-decoration: underline;
      }
    }
    a {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      display: block;
      padding: 0 0 30px 0;
      text-transform: uppercase;
      @include breakpoint("max-sm") {
        padding-bottom: 20px;
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 20px;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    .gridItems {
      .cardImages {
        img {
          width: 100%;
          height: 292px;
        }
      }
      .cardDetails {
        padding: map-get($pixel, md) * 3 - 5px 0 0 0;
        h2 {
          color: map-get($colors, black-3);
          @include heading-3;
          margin: 0 0 map-get($pixel, md) 0;
          // span {
          //     padding-left: 10px;
          // }
          @include breakpoint("max-sm") {
            font-size: 24px;
            line-height: 31px;
          }
        }
        p {
          @include heading-4;
          color: map-get($colors, black-4);
          margin: 0 0 map-get($pixel, md) 0;
        }
        a {
          @include heading-4;
          font-family: "Playfair Display", serif;
          font-style: italic;
        }
      }
    }
  }
}
