@import "../../../styles/mixins/global.scss";

.paginationAlignment {
  @include flex-center;
  justify-content: map-get($layout, center);
  .leftArrow,
  .PageCounter,
  .RightArrow {
    width: 42px;
    margin-right: 4px;
    cursor: pointer;
    height: 42px;
    @include flex-center;
    justify-content: map-get($layout, center);
  }
  .RightArrow {
    margin-right: 0px;
  }
  .activePage {
    background-color: map-get($colors, black-4);
    color: map-get($colors, white-color) !important;
  }
  .paginationBox {
    @include flex-center;
  }
  .PageCounter {
    @include text-md;
    transition: 0.3s ease-in-out;
    color: map-get($colors, black-4);
    &:hover {
      background-color: map-get($colors, black-3);
      color: map-get($colors, white-color);
    }
  }
}
