// @use './mixins/breakpoint.scss';
// @use './mixins/typography.scss';
// @use './mixins/columns.scss';
// @use './function/columns.scss';
// @use './layout/container.scss';
// @use './mixins/index.scss';

@import "../function/columns.scss";
@import "../layout/container.scss";
@import "../mixins/columns.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/index.scss";
@import "../mixins/typography.scss";
@import "../function/function.scss";
@import "../mixins/variable.scss";

$Power2EaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$easing: $Power2EaseOut;

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  text-decoration: none;
}
button:focus {
  outline: none;
}

// page title flip//

.c-header_title_line {
  display: block;
  opacity: 0;
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(-80deg);
  transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
  transition-duration: 0.8s;

  .is-reveal & {
    transform: none;
    opacity: 1;
    transition-duration: 0.8s;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        transition-delay: $i * 0.5s;
      }
    }
  }

  &.-version {
    text-transform: none;
    font-size: 0;
  }
}
.c-header_title {
  // position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  perspective: 600px;
  -webkit-perspective: 600px;
}

.italic-class {
  font-style: italic;
  font-weight: 300;
}

.flipImage {
  clip-path: inset(100% 0% 0% 0%);
  transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
  &.is-reveal {
    clip-path: inset(0% 0% 0% 0%);
  }
}

@mixin outline-medium-button {
  padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
  border-radius: 999px;
  min-width: 146px;
  height: 50px;
  border: 1px solid map-get($colors, black-3);
  @include text-sm-bold;
  color: map-get($colors, black-1);
  background-color: transparent;
  @include flex-center;
  cursor: pointer;
  justify-content: center;
  span {
    padding-right: map-get($pixel, md);
  }
  &:hover {
    background-color: map-get($colors, black-1);
    color: map-get($colors, white-color);
    svg {
      path {
        fill: map-get($colors, white-color);
      }
    }
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #ffff;
}

.footerBottomAlignment {
  padding: map-get($pixel, md) * 11 0 0 0;
  @include breakpoint("max-md") {
    padding: 0;
  }
}
