@import '../../../styles/mixins/global.scss';


.OurApproachAlignment {
    padding: map-get($pixel, md ) * 16 0;
    @include breakpoint("max-md") {
        padding: map-get($pixel, md ) * 12 0;
    }
    @include breakpoint("max-sm") {
        padding: map-get($pixel, md ) * 6 0 map-get($pixel, md ) * 6 0;
    }
    .subTextGrid {
        @include grid-layout;
        grid-template-columns: repeat(2 , 1fr);
        gap: 0 map-get($pixel, xl );
        justify-content: space-between;
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: map-get($pixel, md ) * 2 0;
        }
        .subTextGridItems {
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: map-get($pixel , md ) * 2 + 2px;
            }       
        }
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 590px 1fr;
        gap: 0 100px;
        justify-content: space-between;
        padding: 0 0 map-get($pixel,xl ) * 2 0;
        @include breakpoint("max-sm") {
            padding: 0 0 map-get($pixel, md ) * 4 0;
        }
        @include breakpoint("max-md") {
            grid-template-columns: repeat(2 , 1fr);
            gap: 0 10px;
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 24px 0;
        }
        .gridItems {
            h1 {
                @include heading-1;
                margin: 0;
                color: map-get($colors,black-3);
                @include breakpoint("max-md") {
                    @include heading-3;
                }
                @include breakpoint("max-sm") {
                    @include heading-1;
                }
            }
            h6 {
                @include text-md-playfir;
                text-transform: uppercase;
                text-align: right;
                color: map-get($colors, blue-text-color );
                @include breakpoint("max-sm") {
                    text-align: left;
                }
            }

        }
    }
}
