@import "../../styles/mixins/global.scss";
@import "../../styles/mixins/variable.scss";

.NewsDetailsBanner {
  background-image: url("../../Assets/Images/news-details.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  @include breakpoint("max-sm") {
    height: auto;
    padding: 0px 0 162px 0;
    background-image: url("../../Assets/Images/news-details-mobile.png");
  }
  &::before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 4px);
    content: "";
  }
  .textAlignment {
    position: relative;
    padding: 165px 0 0 0;
    @include breakpoint("max-sm") {
      padding: 79px 0 0 0;
    }
    h1 {
      padding: 0 60px;
      @include heading-large;
      color: map-get($colors, white-color);
      margin: 0;
      @include breakpoint("max-sm") {
        padding: 12px;
        margin: 12px;
        @include heading-1;
        br {
          display: none;
        }
      }
    }
  }
}
