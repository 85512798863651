@function color-type($color-use) {
    @return map-get($colors , $color-use )
};


@function layout-type($layout-use) {
    @return map-get($layout, $layout-use )
};


@function pixel-type($pixel-repeat) {
    @return map-get($pixel, pixel-repeat )
};



@mixin flex-center {
    display: map-get($layout, flex );
    align-items: map-get($layout, center );
}

@mixin flex-between {
    @include flex-center();
    justify-content: space-between;
}

@mixin flex-around {
    @include flex-center();
    justify-content: space-around;
}

@mixin grid-layout {
    display: map-get($layout, grid);
}

