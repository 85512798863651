@import "../../styles/mixins/global.scss";

.headerAlignment {
  @include flex-between;
  padding: map-get($pixel, xl) * 2 0;
  position: map-get($layout, relative);
  border-bottom: 1px solid #c5c5c5;
  .logo {
    img {
      cursor: map-get($layout, pointer);
    }
  }
  .mobileMenu {
    display: none;
    @include breakpoint("max-md") {
      display: block;
    }
  }
  .Menu {
    @include breakpoint("max-md") {
      display: none;
    }
    nav {
      ul {
        @include flex-center;
        list-style: none;
        li {
          text-decoration: none;
          position: map-get($layout, relative);
          margin-right: map-get($pixel, md) * 4;
          @include text-sm-bold;
          font-weight: 500;
          color: map-get($colors, white-color);
          font-style: normal;
          cursor: map-get($layout, pointer);
        }
        a {
          color: map-get($colors, white-color);
          @include text-sm-bold;
          font-weight: 500;
        }
        li:last-child {
          margin-right: map-get($pixel, null);
        }
      }
    }
  }
}

.Menu ul li {
  overflow: hidden;
}
.Menu ul li span:first-child {
  transform: translateY(0);
  display: inline-block;
  transition: 0.2s ease-in-out;
}

.Menu ul li:hover > span {
  transform: translateY(-100%);
  display: inline-block;
  transition: 0.2s ease-in-out;
}

.Menu ul li:hover span:last-child {
  transform: translateY(0);
  transition: 0.2s ease-in-out;
}
.Menu ul li span:last-child {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.mobileHeader {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99999;
  .mobileHeaderDesign {
    background-color: map-get($colors, white-color);
    height: 100vh;
    .logoIconAlignment {
      padding: 30px 30px 10px 30px;
      @include flex-between;
    }
    .mobileMenu {
      padding: 10px 30px 30px 30px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          @include heading-5;
          padding: 30px 0;
          border-bottom: 1px solid map-get($colors, black-4);
        }
        li:last-child {
          padding: 30px 0 0 0;
          border-bottom: none;
        }
      }
      a {
        color: map-get($colors, black-4);
      }
    }
  }
}

.mobileHeaderHidden {
  transform: translateX(-100%);
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.mobileHeaderShow {
  transform: translateX(0%);
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.mobileCloseImageSize {
  width: 24px;
  height: 2px;
}

.mobileCloseIconAlignment {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
