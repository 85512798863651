@import "../../../styles/mixins/global.scss";

.videoPlayerAlignment {
  img {
    width: 100%;
    object-fit: cover;
    height: 600px;
    min-height: 600px;
    @include breakpoint("max-sm") {
      height: 400px;
      min-height: 400px;
    }
  }
}
