@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';


.RemovingFrictionAlignment {
    padding: map-get($pixel, md ) * 20 0 map-get($pixel, md ) * 8 0;
    @include breakpoint("max-sm") {
        padding: 80px 0;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0 , 1fr) 570px;
        gap: 0 map-get($pixel, md ) * 10;
        align-items: map-get($layout, center );
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap:60px 0;
        }
        @include breakpoint("max-sm") {
            gap: 40px 0;
        }
        .gridItems {
            h1 {
                @include heading-1;
                margin: 0 0 map-get($pixel, md ) * 2;
                color: map-get($colors, black-1 );
                @include breakpoint("max-sm") {
                    @include heading-3;
                    margin: 0 0 34px 0;
                }
            }
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: 24px;
                margin: 0;
            }

            img {
                width: 100%;
                height: 490px;
                object-fit: cover;
                @include breakpoint("max-sm") {
                    height: 242px;
                }
            }
        }
    }
}