@import "../../styles/mixins/global.scss";

.ContactAlignment {
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 10 + 0 0;
  @include breakpoint("max-sm") {
    padding: 60px 0;
  }
  .pageTitle {
    h1 {
      @include heading-large;
      color: map-get($colors, black-3);
      max-width: 771px;
      @include breakpoint("max-md") {
        max-width: 100%;
      }
      @include breakpoint("max-sm") {
        @include heading-9;
      }
    }
  }
  .childText {
    @include flex-center;
    justify-content: flex-end;
    padding: map-get($pixel, sm) 0 0 0;
    p {
      @include text;
      color: map-get($colors, black-4);
      line-height: map-get($pixel, md) * 2 + 3px;
      width: 448px;
      @include breakpoint("max-sm") {
        width: 100%;
        margin: 20px 0 0 0;
      }
    }
  }
}

.contactBannerAlign {
  padding: 0 0 200px 0;
  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include breakpoint("max-md") {
      height: 400px;
    }
  }
}

.contactNewBanner {
  background: #f6f9ff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99999;
}
