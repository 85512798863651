@import "../../../styles/mixins/global.scss";

.GeneratingInterestAlignment {
  padding: map-get($pixel, md) * 16 0 0 0;
  @include breakpoint("max-md") {
    padding: 80px 0;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 426px;
    gap: 0 133px;
    padding-right: max(80px, calc((100vw - 1140px) / 2));
    @include breakpoint("max-md") {
      padding-right: 20px;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 40px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
      padding: 0 30px;
    }
    @include breakpoint("max-sm") {
      .gridItems:nth-child(2) {
        display: grid;
      }
      .imageStyle {
        order: 3;
        margin: 60px 0 0 0;
      }
    }
    .gridItems {
      .fullViewImage {
        img {
          width: 100%;
          height: 550px;
          object-fit: cover;
          @include breakpoint("max-md") {
            height: 350px;
          }
          @include breakpoint("max-sm") {
            height: 386px;
          }
        }
      }
      .sectionTitle {
        padding-left: max(80px, calc((100vw - 1140px) / 2));
        padding-bottom: map-get($pixel, md) * 12;
        @include breakpoint("max-md") {
          padding-left: 20px;
        }
        @include breakpoint("max-sm") {
          padding-bottom: 60px;
          padding-left: 0px;
        }
        h1 {
          @include heading-1;
          color: map-get($colors, black-3);
          padding-right: 4rem;
          margin: 0 0 map-get($pixel, md) * 3 + 4px 0;
          @include breakpoint("max-sm") {
            padding-right: 0px;
          }
        }
        p {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 2px;
        }
      }
      // padding-left: max(80px, calc((100vw - 1140px) / 2));a
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        text-align: right;
        padding-bottom: map-get($pixel, md) * 8;
        @include breakpoint("max-sm") {
          padding-bottom: 40px;
          display: none;
        }
      }
      .imageStyle {
        img {
          width: 100%;
          height: 428px;
          object-fit: cover;
          @include breakpoint("max-md") {
            height: 350px;
          }
          @include breakpoint("max-sm") {
            height: 300px;
          }
        }
      }
      .imageRelatedText {
        padding: map-get($pixel, md) * 8 0 0 0;
        p {
          @include text;
          color: map-get($colors, light-text-color);
          font-family: "Playfair Display", serif;
          margin: 0 0 map-get($pixel, md) * 8 0;
          font-style: italic;
          text-align: right;
          @include breakpoint("max-sm") {
            display: none;
          }
        }
        span {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 2px;
        }
      }
    }
  }
}

.mobileViewTextShow {
  display: none;
  h5 {
    @include text-md;
    color: map-get($colors, blue-text-color);
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Playfair Display", serif;
  }
  @include breakpoint("max-sm") {
    display: block;
  }
}

.imageStyle {
  h6 {
    margin: 20px 0 0 0;
    @include text;
    text-align: right;
    font-weight: 400;
    font-family: "Playfair Display", serif;
    color: map-get($colors, light-text-color);
    display: none;
    @include breakpoint("max-sm") {
      display: block;
    }
  }
}
