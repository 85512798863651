@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.TechnologyImproveAlignment {
    padding: map-get($pixel, md ) * 16 0;
    @include breakpoint("max-sm") {
        padding: 80px 0 60px 0;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 570px minmax( 0 , 1fr);
        gap: 0 map-get($pixel, md ) * 10;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 60px 0;
        }
        @include breakpoint("max-sm") {
            gap: 40px 0;
        }
        .gridItems:nth-child(1) {
            order: 2;
        }
        .gridItems {
            img {
                width: 100%;
                height: 490px;
                object-fit: cover;
                @include breakpoint("max-sm") {
                    height: 242px;
                }
            }
            h1 {
                @include heading-1;
                font-weight: 400;
                color: map-get($colors, black-3 );
                margin: 0 0 map-get($pixel, md ) * 2 0;
                @include breakpoint("max-sm") {
                    @include heading-3;
                    margin: 0 0 16px 0;
                }
            }
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: 24px;
                letter-spacing: -0.01em;
            }
        }
    }
}