@import "../../../styles/mixins/global.scss";

.OurHistoryAlignment {
  padding-bottom: map-get($pixel, md) * 16;
  @include breakpoint("max-md") {
    padding-bottom: map-get($pixel, md) * 12;
  }
  @include breakpoint("max-sm") {
    padding-bottom: map-get($pixel, md) * 8;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 590px 1fr;
    gap: 0 map-get($pixel, xl) * 4;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0 map-get($pixel, md) * 6;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 8 0;
    }
    .gridItems {
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 319px;
        }
      }
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
      }
      h1 {
        @include heading-1;
        margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 4 0;
        color: map-get($colors, black-3);
        margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          font-size: 40px;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 2 0;
        line-height: map-get($pixel, md) * 2 + 2px;
        span {
          font-family: "Playfair Display", serif;
        }
      }
      p:last-child {
        margin: 0;
      }
    }
  }
}
