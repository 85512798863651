@use "../mixins/breakpoint.scss";

@mixin heading-large {
  font-family: "Playfair Display", serif;
  font-size: 65px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: -0.01em;
}

@mixin heading-medium {
  font-family: "Playfair Display", serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.01em;
}

@mixin heading-1 {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 48px;
}

@mixin heading-9 {
  font-family: "Playfair Display", serif;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 60px;
}

@mixin heading-2 {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 32px;
}

@mixin heading-3 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 40px;
}

@mixin heading-6 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 43px;
}

@mixin heading-4-playfair {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  line-height: 30px;
  font-weight: 700;
}

@mixin text-md {
  font-size: 18px;
  line-height: 27px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

@mixin text-md-playfir {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  font-family: "Inter", sans-serif;
  font-weight: 500;
}

@mixin text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: -0.01em;
}

@mixin heading-4 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  line-height: 30px;
  font-weight: 400;
}

@mixin heading-5 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  line-height: 31px;
  font-weight: 400;
}

@mixin text-sm-bold {
  font-size: 14px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

@mixin text-sm {
  font-size: 14px;
  line-height: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

@mixin text-xs-bold {
  font-size: 12px;
  line-height: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

// font-family: 'Lato', sans-serif;
