@import '../../../styles/mixins/global.scss';


.FeaturedPropertiesAlignment {
    padding: 0 0 map-get($pixel, md ) * 16 0;
    @include breakpoint("max-md") {
        padding: 0 0 map-get($pixel, md ) * 12 0;
    }
    .pageTitle {
        padding: 0 0 map-get($pixel, md ) * 8 0;
        @include breakpoint("max-sm") {
            padding: 0 0 map-get($pixel, md ) * 4 0;
        }
        h1 {
            @include heading-1;
            color: map-get($colors, black-1 );
            margin: 0 0 map-get($pixel, md ) * 2 0;
        }
        a {
            @include text-md;
            color: map-get($colors, blue-text-color );
            text-transform: uppercase;
            font-family: 'Playfair Display', serif;
        }
    }
    .cardGrid {
        @include grid-layout;
        grid-template-columns: repeat(3 , 1fr);
        gap: 0 map-get($pixel, md ) * 2 ;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(2 , 1fr);
        }
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 20px 0;
        }
        @include breakpoint("max-md") {
            .cardGridItems:last-child {
                display: none;
            }
        }
        .cardGridItems {
            .cardDetails {
                padding: map-get($pixel, md ) * 2 + 5px 0 0 0;
                h3 {
                    @include heading-2;
                    font-weight: 600;
                    color: map-get($colors, black-3 );
                    margin: 0 ;
                    padding-bottom: map-get($pixel, md ) + 6px;
                    border-bottom: 1px solid #EDEDED;
                }
                p {
                    @include text;
                    color:  map-get($colors, black-4 );
                    margin: map-get($pixel, md ) + 6px 0 0 0;
                }
            }
            .cardImage {
                img {
                    width: 100%;
                    height: 470px;
                    object-fit: cover;
                }
            }
        }
    } 
}