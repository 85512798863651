@import '../../../styles/mixins/global.scss';

.missionAndVisonAlignment {
	padding: 160px 0;
	@include breakpoint('max-sm') {
		padding: 92px 0 80px 0;
	}
	.containerSpaceRemove {
		padding: 0 !important;
	}
	.grid {
		@include grid-layout;
		grid-template-columns: 426px 1fr;
		gap: 0 132px;
		justify-content: space-between;
		@include breakpoint('max-md') {
			grid-template-columns: repeat(1, 1fr);
			gap: 80px 0;
		}
		@include breakpoint('max-sm') {
			gap: 40px 0;
		}
		.gridItems {
			.mobileViewAlignSpace {
				@include breakpoint('max-sm') {
					padding: 0 30px;
				}
			}
			a {
				@include text-md-playfir;
				color: map-get($colors, blue-text-color);
				display: block;
				text-transform: uppercase;
				text-align: map-get($layout, right);
				@include breakpoint('max-sm') {
					padding: 0 30px;
					text-align: right;
				}
			}
			p {
				@include text;
				color: map-get($colors, black-4);
				margin: 0;
				line-height: map-get($pixel, md) * 2 + 2px;
				margin: 0 0 map-get($pixel, md) * 2 0;
			}
			p:last-child {
				margin-bottom: 0px;
			}
			.missionImage {
				padding: map-get($pixel, xl) * 6 + 8px 0 0 0;
				@include breakpoint('max-md') {
					padding-top: 40px;
				}
				@include breakpoint('max-sm') {
					padding: 40px 30px 0 30px;
				}
				img {
					width: 100%;
					height: 525px;
					object-fit: cover;
					@include breakpoint('max-sm') {
						float: right;
					}
					@include breakpoint('max-sm') {
						width: 245px;
						height: 251px;
					}
				}
			}
			.childImage {
				padding: map-get($pixel, xl) * 6 + 8px 0 0 0;
				@include breakpoint('max-md') {
					padding-top: 40px;
					padding-left: 30px;
					padding-right: 30px;
				}
				img {
					width: 100%;
					height: 428px;
					object-fit: cover;
					@include breakpoint('max-sm') {
						height: 379px;
					}
				}
			}
			h1 {
				@include heading-1;
				color: map-get($colors, black-3);
				margin: 0 0 map-get($pixel, md) * 3 + 2px;
				@include breakpoint('max-sm') {
					@include heading-6;
					padding-right: 1rem;
					margin-bottom: map-get($pixel, md) * 4;
				}
			}
		}
	}
}

.LogoBanner {
	background-color: map-get($colors, background-color);
	padding: map-get($pixel, xl) * 4 0;
	overflow-x: hidden;
	@include breakpoint('max-sm') {
		padding: map-get($pixel, md) * 4 0;
	}
	.Marquee {
		animation: move 15s linear infinite;
		@keyframes move {
			to {
				transform: translateX(-100%);
			}
		}
	}
	.AlignmentContent {
		width: 100%;
		@include flex-center;
		white-space: nowrap;
		div {
			margin-left: map-get($pixel, xl) * 3;
			h1 {
				@include heading-large;
				line-height: 90px;
				color: map-get($colors, black-4);
				margin: 0;
				font-style: italic;
				@include breakpoint('max-sm') {
					@include heading-6;
				}
			}
		}
	}
}

.missionMobileNone {
	@include breakpoint('max-sm') {
		display: none;
	}
}

.missionMobileShowImage {
	display: none;
	padding: 0 30px;
	padding-top: 30px;
	img {
		width: 219px;
		height: 220px;
	}
	@include breakpoint('max-sm') {
		display: block;
	}
}

.missinNewAlignment {
	padding: map-get($pixel, md) * 16 0;
	@include breakpoint('max-sm') {
		padding: 92px 0 0px 0;
	}
	.newTextGrid {
		@include grid-layout;
		grid-template-columns: 723px minmax(0, 1fr);
		gap: 0 10px;
		padding: 0 0 map-get($pixel, md) * 6 0;
		justify-content: space-between;
		@include breakpoint('max-md') {
			grid-template-columns: repeat(1, 1fr);
			gap: 27px;
			padding: 0 0 30px 0;
		}
		@include breakpoint('max-sm') {
			grid-template-columns: repeat(1, 1fr);
			gap: 30px;
			padding: 0 0 0 0;
		}
		.newTextGridItems {
			h1 {
				font-size: 60px;
				font-weight: 500;
				letter-spacing: -0.01em;
				font-family: 'Playfair Display', serif;
				line-height: 72px;
				color: map-get($colors, black-3);
				@include breakpoint('max-sm') {
					@include heading-1;
				}
			}
			a {
				@include text-md-playfir;
				color: map-get($colors, blue-text-color);
				display: block;
				text-align: left;
				@include breakpoint('max-md') {
					text-align: left;
				}
				@include breakpoint('max-sm') {
					text-align: left;
					display: none;
				}
			}
		}
	}

	.firstGrid {
		display: grid;
		grid-template-columns: 1fr 0.8fr;
		margin-top: 20px;
		gap: 40px;

		img {
			width: 100%;
			height: auto;
			display: block;
			margin-left: auto;
		}

		@include breakpoint('max-sm') {
			grid-template-columns: 1fr;
		}
	}

	.secondGrid {
		display: grid;
		grid-template-columns: 0.8fr 1fr;
		gap: 40px;
		margin-top: -80px;

		p {
			padding-top: 160px;
		}
		img {
			width: 100%;
			height: auto;
			display: block;
		}

		@include breakpoint('max-md') {
			margin-top: 40px;
			p {
				padding-top: 0px;
			}
		}

		@include breakpoint('max-sm') {
			grid-template-columns: 1fr;
			img {
				order: 2;
			}
		}
	}
}

.leftAlignmentContent {
	padding-left: max(80px, calc((100vw - 1120px) / 2));
	@include breakpoint('max-md') {
		padding: 0 40px;
	}
	@include breakpoint('max-sm') {
		padding: 0;
	}
	.secGrid {
		@include grid-layout;
		grid-template-columns: 761px minmax(0, 1fr);
		gap: 0 132px;
		@include breakpoint('max-md') {
			grid-template-columns: repeat(1, 1fr);
			gap: 60px 0;
		}
		@include breakpoint('max-sm') {
			grid-template-columns: repeat(1, 1fr);
			gap: 0;
		}
		.secGridItems {
			.mobileTextShow {
				display: none;
				@include breakpoint('max-sm') {
					display: block;
				}
			}
			a {
				@include text-md-playfir;
				color: map-get($colors, blue-text-color);
				display: block;
				text-align: right;
				@include breakpoint('max-md') {
					text-align: left;
				}
				@include breakpoint('max-sm') {
					text-align: left;
					padding: 0 30px 80px 30px;
				}
			}
			p {
				@include heading-4;
				color: map-get($colors, black-3);
				margin: 0 0 110px 0;
				max-width: 671px;
				@include breakpoint('max-sm') {
					max-width: 100%;
					margin: 0 0 27px 0;
					padding: 0 30px;
					@include text-md;
				}
			}
			.fullImageDesign {
				@include breakpoint('max-sm') {
					padding: 0 30px;
					display: none;
				}
				img {
					width: 100%;
					height: 454px;
					@include breakpoint('max-sm') {
						height: 220px;
					}
				}
			}
			.childImageDesign {
				margin: 50px 0 0 0;
				@include breakpoint('max-sm') {
					display: none;
					margin: 30px 0 0 0;
					justify-content: flex-end;
				}
				img {
					width: 100%;
					height: 320px;
					object-fit: cover;
					@include breakpoint('max-sm') {
						width: 245px;
						height: 251px;
						object-fit: cover;
					}
				}
			}
		}
	}
}

.mobileviewSectionShow {
	display: none;
	padding: 0 0 80px 0;
	@include breakpoint('max-sm') {
		display: block;
	}
	.MobileViewImageFirst {
		display: flex;
		justify-content: flex-end;
		padding: 0 0 30px 0;
		img {
			width: 245px;
			height: 251px;
		}
	}
	.fullImageBoxAlign {
		img {
			width: 100%;
			height: 220px;
			object-fit: cover;
		}
	}
}
