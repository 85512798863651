@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/variable.scss";

.exploreCommunityAlignment {
  padding: map-get($pixel, md) * 16 0;
  @include breakpoint("max-sm") {
    padding: 80px 0;
  }
  .pageTitle {
    padding: 0 0 map-get($pixel, md) * 4 0;
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      text-transform: capitalize;
      font-family: "Playfair Display";
      font-weight: 400 !important;
      letter-spacing: -0.01em;
      margin: 0 0 map-get($pixel, md) * 2 0;
      @include breakpoint("max-sm") {
        @include heading-3;
      }
    }
    a {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      text-transform: uppercase;
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    .gridItems:nth-child(2),
    .gridItems:nth-child(4) {
      @include breakpoint("max-md") {
        border: none;
      }
    }

    .gridItems:nth-child(3) {
      @include breakpoint("max-md") {
        padding-left: 0px;
      }
    }
    .gridItems:first-child {
      padding-left: 0px;
    }
    .gridItems:last-child {
      border: none;
      padding-right: 0px;
    }
    .gridItems {
      padding: 0 28px;
      justify-content: space-between;
      border-right: 1px solid #4f5e79;
      @include breakpoint("max-sm") {
        padding: 0;
        border: none;
      }
      h2 {
        @include heading-2;
        color: #121d24;
        font-size: 19px;
        line-height: 29px;
        cursor: pointer;
        margin: 0 0 32px 0;
        font-weight: 600;
        @include breakpoint("max-sm") {
          padding-right: 45px;
        }
        br {
          display: none;
        }
      }
      button {
        min-width: 146px;
        height: 50px;
        border: 1px solid map-get($colors, black-5);
        background-color: map-get($colors, white-color);
        padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
        @include text-sm-bold;
        color: map-get($colors, black-5);
        border-radius: map-get($pixel, md) * 2 + 4px;
        background-color: transparent;
        @include flex-center;
        justify-content: center;
        cursor: map-get($layout, pointer);
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: map-get($colors, black-5);
          color: map-get($colors, white-color);
          svg {
            path {
              fill: map-get($colors, white-color);
            }
          }
        }
        @include breakpoint("max-sm") {
          padding: 5px 20px;
        }
        span {
          padding-right: map-get($pixel, md) - 2px;
        }
      }
    }
  }
}
