@import "../../../styles/mixins/global.scss";

.enquiresFormAlignment {
  padding: map-get($pixel, xl) * 8 0;
  background-color: #f6f9ff;
  @include breakpoint("max-md") {
    padding: 120px 0;
  }
  @include breakpoint("max-sm") {
    padding: 60px 0;
  }
  .formControl {
    position: relative;
    .dropDownAlignment {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    input {
      width: -webkit-fill-available;
      height: 34px;
      padding: 0;
      position: map-get($layout, relative);
      border: none;
      border-bottom: 1px solid #8e939d;
      @include text;
      color: map-get($colors, gray-1);
      background-color: transparent;
      @include breakpoint("max-md") {
        width: 100%;
      }
      &:focus {
        outline: none;
      }
    }
    ::placeholder {
      @include text;
      color: map-get($colors, gray-1);
    }
  }
  .cusDropdownFiled {
    padding-bottom: 40px;
    .inputRelative {
      position: relative;
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 468px 1fr;
    gap: 0 90px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    .gridItems {
      .subMitButton {
        padding: map-get($pixel, md) * 4 0 0 0;
        button {
          padding: map-get($pixel, sm) map-get($pixel, sm) + 2px;
          @include text-sm-bold;
          color: map-get($colors, black-1);
          background-color: transparent;
          border: 1px solid map-get($colors, black-1);
          min-width: 120px;
          height: 50px;
          border-radius: map-get($pixel, md) * 3 + 0px;
          @include flex-center;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          justify-content: center;
          @include breakpoint("max-sm") {
            padding: 5px 20px;
            justify-content: space-between;
          }
          &:hover {
            background-color: map-get($colors, black-1);
            color: map-get($colors, white-color);
            svg {
              path {
                fill: map-get($colors, white-color);
              }
            }
          }
          span {
            padding-right: map-get($pixel, md) - 2px;
          }
        }
      }
      .formControl {
        textarea {
          width: -webkit-fill-available;
          height: 84px;
          padding: 0;
          margin: 40px 0 0 0;
          border: none;
          border-bottom: 1px solid #8e939d;
          @include text;
          color: map-get($colors, gray-1);
          background-color: transparent;
          @include breakpoint("max-md") {
            width: 100%;
          }
          &:focus {
            outline: none;
          }
        }
        ::placeholder {
          @include text;
          color: map-get($colors, gray-1);
        }
      }
      .formGrid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 21px;
        justify-content: space-between;
      }
      p {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        margin: 0 0 38px 0;
        @include breakpoint("max-sm") {
          margin: 0 0 60px 0;
        }
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0;
        line-height: map-get($pixel, md) * 5 + 2px;
        @include breakpoint("max-sm") {
          @include heading-6;
        }
      }
    }
  }
}

.thankyouMessage {
  display: flex;
  // align-items: center;
  width: 100%;
  background: #d9f5e6;
  padding: map-get($pixel, sm) * 4;
  .checkIconAlign {
    padding-top: 6px;
    padding-right: 14px;
  }
  h6 {
    @include heading-4;
    font-size: 16px;

    color: map-get($colors, black-3);
  }
}
