.slick-initialized .slick-slide {
  border: 0;
}

.slick-slide div {
  outline: none;
}

.slider-left {
  width: 36%;
  padding: 40px 80px 40px 0;
}
.slide-nav {
  width: 100%;
  display: block;
  object-fit: cover;
  margin-bottom: 10px;
}
.slider-right {
  width: 64%;
}
.slide-main {
  width: 100%;
  display: block;
  object-fit: cover;
}
.slider-container {
  padding: 0 20px !important;
  display: flex;
}

.slider-right .slick-slide {
  padding: 40px 0;
}
.slick-slide img {
  width: 100%;
}


.slider-right .slick-active img {
  width: 80%;
  float: right;
}
.slider-right .slick-current img {
  width: 100%;
}

.team-member-position {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #1663ae;
}
.team-member-name {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  margin-top: 20px;
  line-height: 120%;
  color: #333b49;
}
.team-member-about-hidden {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4f5e79;
  margin-top: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 168px;
  transition: 0.3s;
  line-height: 150%;
}
.team-member-about-show {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4f5e79;
  margin-top: 40px;
  line-height: 150%;
  max-height: 100vh;
  transition: 0.3s;
}
.show-more {
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.01em;
  color: #333b49;
  font-weight: 500;
  cursor: pointer;
  display: block;
  margin: 20px 0 20px 0;
}
.slider-arrow {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #7b8595;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.arrow-right {
  margin-left: 68px;
}

@media only screen and (max-width: 540px) {
  .slider-container {
    padding: 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .slider-left {
    width: 100%;
    padding: 20px 0 40px 0;
  }
  .slider-right {
    width: 100%;
    padding-top: 40px;
  }
  .slider-right .slick-slide {
    padding: 0;
  }
  .slider-container {
    padding: 0 !important;
  }
  .arrow-left {
    position: initial;
    margin-left: calc(50% - 64px);
    margin-bottom: 20px;
  }
  .arrow-right {
    position: absolute;
    top: 0;
    left: calc(4px + 50%);
    margin: 0;
  }
}

@media screen and (max-width: 992px) and (min-width: 541px) {
  .slider-container {
    padding: 0;
  }
  .slider-left {
    width: 50%;
    padding: 20px 20px 40px 0;
  }
  .slider-right {
    width: 50%;
    padding-top: 20px;
  }
  .slider-right .slick-slide {
    padding: 0;
  }
  .slider-container {
    padding: 0 !important;
  }
}
