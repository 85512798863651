@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.NewsDetailsTextAlignment {
    padding: map-get($pixel, md ) * 16  0;
    @include breakpoint("max-sm") {
        padding: 80px 0;
    }
    .boxCenterAlignment {
        @include flex-center;
        justify-content: map-get($layout, center );
        .boxWidth {
            width: min(100% , 873px);
            @include breakpoint("max-md") {
                width: 100%;
            }
        }
    }
    .pageTitle {
        padding: 0 0 map-get($pixel, md ) * 6 0;
        @include breakpoint("max-sm") {
            padding: 0 0 32px 0;
        }
        h1 {
            @include heading-2;
            color: map-get($colors, black-3 );
            margin: 0 0 map-get($pixel, sm ) + 3px 0;
            span {
                text-decoration: underline;
            }
        }
        h6 {
            @include text-md;
            color: map-get($colors, black-4 );
            margin: 0;
        }
    }
    .childTextStyle {
        p {
            @include text-md;
            color: map-get($colors, black-4 );
            margin: 0 0 map-get($pixel, md ) * 2 0;
            @include breakpoint("max-sm") {
                @include text;
                letter-spacing: -0.01em;
            }
        }
        p:last-child {
            margin: 0;
        }
    }
}