@import '../../styles/mixins/global.scss';
@import '../../styles/mixins/variable.scss';

.ScaleAlignment {
    padding: map-get($pixel, md ) * 10 0;
    @include breakpoint("max-sm") {
        padding: map-get($pixel, md ) * 6 0;
    }
    .pageTitle{
        h1 {
            @include heading-large;
            color: map-get($colors, black-3 );
            margin: 0;
            width: min(100% , 773px);
            @include breakpoint("max-md") {
                width: 100%;
            }
            @include breakpoint("max-sm") {
                @include heading-1;

            }
        }
    }
}

.ScaleBanner {
    .scaleBannerSpaceRemove  {
        @include breakpoint("max-sm") {
            padding: 0 !important;
        }
    }
    img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        @include breakpoint("max-sm") {
            height: 400px;
        }
    }
}