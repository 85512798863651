@import "../../styles/mixins/global.scss";
@import "../../styles/mixins/variable.scss";

.TechnologyPageAlignment {
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 11 + 7px 0;
  @include breakpoint("max-sm") {
    padding: 60px 0;
  }
  .pageTitle {
    h1 {
      @include heading-large;
      color: map-get($colors, black-3);
      width: min(100%, 771px);
      @include breakpoint("max-md") {
        width: 100%;
      }
      @include breakpoint("max-sm") {
        @include heading-1;
      }
    }
  }
}

.technologyBanner {
  .spaceRemveImage {
    @include breakpoint("max-sm") {
      padding: 0 !important;
    }
  }
  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include breakpoint("max-md") {
      height: 400px;
    }
  }
}
.communityBannerMobileHidden {
  @include breakpoint("max-sm") {
    display: none;
  }
}
.communityBannerMobileShow {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
}
