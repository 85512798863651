@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/variable.css";

.CommunityVideoAlignment {
  position: relative;
  video {
    width: 100%;
    height: 600px;
    min-height: 600px;
    @include breakpoint("max-sm") {
      height: 400px;
      min-height: 400px;
    }
  }
  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: center;
    @include breakpoint("max-sm") {
      height: 400px;
    }
  }
}


.CommunityVideoAlignment {
  iframe {
    width: 100%;
    height: 600px;
    @include breakpoint("max-sm") {
      height: 400px;
    }
  }
}