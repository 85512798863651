@import "../../styles/mixins/global.scss";
@import "../../styles/mixins/variable.scss";

.CookiesAlignment {
  position: fixed;
  bottom: 40px;
  width: 100%;
  z-index: 999999;
  @include breakpoint("max-sm") {
    bottom: 10px;
  }
  .cookiesBox {
    width: min(100%, 650px);
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    padding: 8px 8px 8px 24px;
    left: 50%;
    // border-radius: 999px;
    background: map-get($colors, white-color);
    @include breakpoint("max-sm") {
      width: calc(100% - 15px);
      padding: 16px 8px 8px 20px;
      // border-radius: 24px;
      bottom: 40px;
    }
    .cookieGrid {
      @include grid-layout;
      grid-template-columns: minmax(0, 1fr) 94px;
      gap: 0 map-get($pixel, md);
      align-items: center;
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px 0;
      }
      .cookieGridItems:last-child {
        @include flex-center;
        justify-content: flex-end;
      }
      .cookieGridItems {
        > button {
          background-color: map-get($colors, black-3);
          @include text-xs-bold;
          font-weight: 700;
          padding: map-get($pixel, md) + 2px map-get($pixel, md) * 2;
          color: map-get($colors, white-color);
          border: none;
          cursor: pointer;
          border-radius: 999px;
          text-align: map-get($colors, white-color);
        }
        p {
          @include text-xs-bold;
          color: map-get($colors, black-3);
          display: block;
          @include breakpoint("max-sm") {
            display: block;
            padding-right: 2rem;
          }
          span,
          a {
            font-weight: 700;
            color: map-get($colors, black-3);
          }
        }
      }
    }
  }
}
