@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/variable.scss";

.NewsInshightsBottomAlignment {
  padding: 0 0 map-get($pixel, md) * 16 0;
  @include breakpoint("max-sm") {
    padding: 0 0 70px 0;
  }
  .viewMoreButton {
    @include flex-center;
    padding: map-get($pixel, md) * 8 0 0 0;
    justify-content: map-get($layout, center);
    @include breakpoint("max-sm") {
      padding: 40px 0 0 0;
      justify-content: flex-start;
    }
    button {
      background-color: map-get($colors, black-1);
      min-width: 124px;
      height: 50px;
      @include flex-center;
      justify-content: center;
      @include text-sm-bold;
      position: relative;
      z-index: 9999;
      color: map-get($colors, white-color);
      cursor: map-get($layout, pointer);
      padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
      border: none;
      border-radius: map-get($pixel, md) * 2 + 4px;
      transition: 0.3s ease-in-out;
      @include breakpoint("max-sm") {
      }
      svg {
        margin-left: map-get($pixel, md) - 2px;
      }
      &:hover {
        border: 1px solid map-get($colors, black-1);
        color: map-get($colors, black-1);
        background-color: transparent;
        svg {
          path {
            fill: map-get($colors, black-1);
          }
        }
      }
      @include breakpoint("max-sm") {
        justify-content: center;
        padding: 5px 20px;
      }
    }
  }
  .pageTitle {
    padding: 0 0 map-get($pixel, md) * 8 0;
    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }
    a {
      @include text-md-playfir;
      font-weight: 500;
      padding: 0 0 map-get($pixel, md) * 2 0;
      display: block;
      color: map-get($colors, blue-text-color);
    }
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      margin: 0;
      @include breakpoint("max-sm") {
        @include heading-3;
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 map-get($pixel, md) * 2;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    .gridItems {
      .cardImage {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }
      .cardDetails {
        padding: map-get($pixel, md) * 2 + 5px 0 0 0;
        h3 {
          @include heading-2;
          color: map-get($colors, black-3);
          margin: 0;
          font-weight: 600;
          padding: 0 0 map-get($pixel, sm) * 3 0;
          border-bottom: 1px solid #ededed;
          cursor: pointer;
        }
        p {
          padding: map-get($pixel, md) + 6px 0 0 0;
          @include text;
          color: map-get($colors, black-4);
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
