@import "../../styles/mixins/global.scss";

.NewsAndInsightsAlignment {
  padding: map-get($pixel, md) * 10 + 4px 0 map-get($pixel, md) * 15 0;
  @include breakpoint("max-md") {
    padding: 60px 0 80px 0;
  }
  .pageTitle {
    padding: 0 0 map-get($pixel, md) * 6 0;
    h1 {
      @include heading-large;
      text-align: map-get($layout, center);
      color: map-get($colors, black-3);
      margin: 0;
      @include breakpoint("max-md") {
        @include heading-1;
      }
      @include breakpoint("max-sm") {
        @include heading-1;
      }
    }
  }
}
