@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.TechnologyRewardsBanner {
    background-color: map-get($colors, backgound-2 );
    padding: map-get($pixel, md ) * 16 0 ;
    @include breakpoint("max-sm") {
        padding: 80px 0;
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 650px minmax(0 , 1fr);
        gap: 0 map-get($pixel, md ) * 8 ;
        justify-content: space-between;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        .gridItems:nth-child(2) {
            display: flex;
            align-items: end;
        }
        .gridItems {
            img {
                width: 100%;
                height: 470px;
                @include breakpoint("max-sm") {
                    height: 228px;
                }
            }
            h1 {
                @include heading-3;
                color: map-get($colors, black-3 );
                @include breakpoint("max-md") {
                    max-width: 560px;
                }
                @include breakpoint("max-sm") {
                    max-width: 100%;
                    @include heading-3;
                }
            }
        }
    }
    .textGrid {
        @include grid-layout;
        grid-template-columns: minmax(0 , 1fr) 538px;
        padding: 0 0 map-get($pixel, md ) * 8 0;
        gap: 0 map-get($pixel, md ) * 13 + 2px;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 40px 0;
        }
        @include breakpoint("max-sm") {
            padding: 0 0 60px 0;
        }
        .textGridItems {
            a {
                color: map-get($colors, blue-text-color );
                @include text-md-playfir;
                display: block;
                text-transform: uppercase;
                padding: 0 0 map-get($pixel, sm ) * 4 0;
            }
            p {
                @include text;
                color: map-get($colors, gray-6 );
                line-height: 24px;
                margin: 0 0 map-get($pixel, md ) * 2 0;
            }
            p:last-child {
                margin: 0;
            }
            h1 {
                @include heading-1;
                color: map-get($colors, black-3 );
                font-weight: 400;
                @include breakpoint("max-sm") {
                    @include heading-3;
                }
            }
        }
    }
}