@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.timelineCenterAlignment {
    padding: 0 0 73px 0;
    display: flex;
    justify-content: center;
    /* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    border: 1px solid #B1BDC9;
    top: 0;
    background-color: #B1BDC9;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  /* timelineContainer around content */
  .timelineContainer {
    padding: 0 60px;
    padding-bottom: 60px;
    position: relative;
    background-color: inherit;
    width: 50%;
    @include breakpoint("max-md") {
      padding: 0 40px;
    }
  }
  
  /* The circles on the timeline */
  .timelineContainer::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #B1BDC9;
    right: -11px;
    border: 4px solid #fff;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  /* Place the timelineContainer to the left */
  .left {
    left: 0;
  }
  
  /* Place the timelineContainer to the right */
  .right {
    left: 50%;
  }
  
  /* Add arrows to the left timelineContainer (pointing right) */
  .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
  }
  
  /* Add arrows to the right timelineContainer (pointing left) */
  .right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }
  
  /* Fix the circle for timelineContainers on the right side */
  .right::after {
    left: -13px;
  }
  
  /* The actual content */
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
    left: 0;
    }
    
    /* Full-width timelineContainers */
    .timelineContainer {
    width: 100%;
    padding-left: 48px;
    padding-right: 0;
    padding-bottom: 60px;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    .timelineContainer::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    }
  
    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
    left: 15px;
    @include breakpoint("max-sm") {
      left: -14px;
    }
    }
    
    /* Make all right timelineContainers behave like the left ones */
    .right {
    left: 0%;
    }
  }
}

.content {
    h2 {
        @include heading-5;
        font-weight: 700;
        color: map-get($colors, black-3 );
        margin: 0 0 map-get($pixel, md ) + 6px 0;
        @include breakpoint("max-sm") {
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 1rem 0; 
        }
    }
    p {
        @include text-md;
        color: map-get($colors, black-4 );
        padding-right: 60px;
        @include breakpoint("max-md" ) {
          padding-right: 0px;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            @include text-md;
            color: map-get($colors, black-4 );
            padding-right: 80px;
            @include breakpoint("max-md") {
              padding-right: 0px;
            }
        }
    }

}

.contentspaceRemove {
    padding-bottom: 0px !important;
}