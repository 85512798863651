@import "../../../styles/mixins/global.scss";

.researchAlignment {
  padding: map-get($pixel, xl) * 8 0;
  @include breakpoint("max-sm") {
    padding: 60px 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 354px 1fr;
    gap: 0 93px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    .gridItems {
      .questionMain {
        padding: map-get($pixel, md) * 2 0;
        border-bottom: 1px solid map-get($colors, border-color);
        .textHidden {
          max-height: 0px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }
        .textShow {
          max-height: calc(100vh - 300px);
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }
        .questionAnswerText {
          padding: map-get($pixel, md) * 2 0 0 0;
          .textAlignment {
            @include flex-between;
            padding: map-get($pixel, md) 0;
            @include breakpoint("max-sm") {
              display: block;
            }
            p {
              @include text-md;
              color: map-get($colors, gray-1);
              margin: 0;
              @include breakpoint("max-sm") {
                margin: 0 0 1.25rem 0;
              }
            }
            span {
              @include text;
              color: map-get($colors, black-1);
              display: block;
            }
          }
        }
        .questionGrid {
          @include grid-layout;
          grid-template-columns: 1fr 15px;
          justify-content: space-between;
          gap: 0 10px;
          .questionGridItems {
            p {
              @include text-md;
              color: map-get($colors, black-3);
            }
            img {
              cursor: map-get($layout, pointer);
            }
          }
        }
      }
      .tabAlignment {
        padding-bottom: map-get($pixel, md) * 4 + 5px;
        @include breakpoint("max-sm") {
          display: none;
        }
        ul {
          @include flex-center;
          list-style: none;
          justify-content: space-between;
          li:first-child {
            color: map-get($colors, black-1);
          }
          li {
            @include heading-3;
            font-size: 33px;
            line-height: 50px;
            font-style: italic;
            color: #b7becb;
          }
        }
      }
      .textStyle {
        a {
          @include text-md;
          font-family: "Playfair Display", serif;
          color: map-get($colors, blue-text-color);
          text-transform: uppercase;
          display: block;
          padding-top: map-get($pixel, md) * 2;
        }
        h1 {
          @include heading-1;
          color: map-get($colors, black-3);
          margin: 0 0 map-get($pixel, md) * 2 + 2px 0;
        }
        p {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 2px;
        }
      }
    }
  }
}
