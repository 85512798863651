@import "../../styles/mixins/global.scss";
@import "../../styles/mixins/typography.scss";

.communityAlign {
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 11 + 7px 0;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 6 0;
  }
  .pageTitle {
    h1 {
      @include heading-large;
      color: map-get($colors, black-3);
      width: 100%;
      font-weight: 400;
      @include breakpoint("max-md") {
        width: 100%;
      }
      @include breakpoint("max-sm") {
        @include heading-1;
      }
    }
  }
}

.communityBanner {
  .communityBannerSpaceRemove {
    @include breakpoint("max-sm") {
      padding: 0px !important;
    }
  }
  .communityBannerMobileHidden {
    @include breakpoint("max-sm") {
      display: none;
    }
  }
  .communityBannerMobileShow {
    display: none;
    @include breakpoint("max-sm") {
      display: block;
    }
  }

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include breakpoint("max-sm") {
      height: 400px;
    }
  }
}
