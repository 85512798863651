@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/variable.scss";

.ResidentialSectionAlignment {
  padding: map-get($pixel, md) * 6 0;
  border-bottom: 1px solid map-get($colors, border-color-3);
  @include breakpoint("max-sm") {
    padding: 60px 0 40px 0;
  }
  .titleAlign {
    padding: 0 0 map-get($pixel, md) * 6 0;
    @include breakpoint("max-sm") {
      padding: 0 0 map-get($pixel, md) * 4 0;
    }
    h1 {
      @include heading-1;
      font-weight: 400;
      color: map-get($colors, black-3);
      margin: 0;
      @include breakpoint("max-sm") {
        @include heading-3;
      }
    }
  }
  .boxEndSide {
    @include flex-center;
    justify-content: flex-end;
    .gridBottomAlign {
      padding: 0 0 map-get($pixel, md) * 6 0;
    }
    .grid {
      @include grid-layout;
      max-width: 873px;
      grid-template-columns: minmax(0, 1fr) 240px;
      gap: 0 map-get($pixel, md) * 11 + 5px;
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        width: 100%;
        max-width: 100%;
      }
      .gridItems {
        h1 {
          @include heading-2;
          text-align: left;
          margin: 0 0 map-get($pixel, sm) * 3 0;
          font-weight: 400;
        }
        p,
        a {
          @include text;
          letter-spacing: -0.01em;
          color: map-get($colors, black-4);
          line-height: 22px;
        }
        img {
          width: 100%;
          height: 240px;
          object-fit: cover;
        }
      }
    }
  }
}

.ResidentialSectionBottomAlignment {
  padding: 0 0 map-get($pixel, md) * 16 0;
  @include breakpoint("max-sm") {
    padding: 0 0 30px 0;
  }
}

.mobileViewHiddenImage {
  @include breakpoint("max-sm") {
    display: none;
  }
}

.mobileViewImaageShow {
  display: none;
  @include breakpoint("max-md") {
    display: block;
  }
}
