@import "../../../styles/mixins/global.scss";

.bottomSpaceRemoveSection {
  padding-bottom: 0px !important;
  @include breakpoint("max-sm") {
    padding-bottom: 80px !important;
  }
}

.TechnologySectionAlignment {
  .gridChildSec {
    @include grid-layout;
    grid-template-columns: 1fr 386px;
    gap: 0 60px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding: 60px 0 0 0;
    }
    @include breakpoint("max-sm") {
      padding: 0;
    }
    @include breakpoint("max-sm") {
      gap: 20px 0;
    }
    @include breakpoint("max-md") {
      .gridItems:nth-child(1) {
        order: 2;
      }
      .gridItems:nth-child(2) {
        order: 1;
      }
    }
    @include breakpoint("max-sm") {
      .gridItems:nth-child(1) {
        order: 2;
      }
      .gridItems:nth-child(2) {
        order: 1;
      }
    }

    .gridItems {
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        display: block;
        text-align: left;
        padding-bottom: map-get($pixel, md) * 4;
        @include breakpoint("max-sm") {
          padding: 0;
        }
      }
      h3 {
        @include heading-3;
        padding-right: 3rem;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          padding-right: 0px;
          display: none;
        }
      }
      button {
        @include outline-medium-button;
        @include breakpoint("max-sm") {
          padding: 0 20px;
          display: none;
        }
      }
      .trasnformingBox {
        background-color: map-get($colors, backgound-2);
        padding: 80px 80px 160px 80px;
        @include breakpoint("max-sm") {
          padding: 30px 30px 60px 30px;
        }
        h1 {
          @include heading-1;
          color: map-get($colors, black-3);
          margin: 0 0 80px 0;
          @include breakpoint("max-sm") {
            margin: 0 0 30px 0;
          }
        }
        h2 {
          @include heading-3;
          color: map-get($colors, black-3);
          margin: 0 0 20px 0;
        }

        p {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 2px;
        }
      }
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 386px 1fr;
    gap: 0 60px;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    @include breakpoint("max-sm") {
      gap: 20px 0;
    }
    .gridItems {
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        display: block;
        text-align: left;
        padding-bottom: map-get($pixel, md) * 4;
        @include breakpoint("max-sm") {
          padding: 0;
        }
      }
      h3 {
        @include heading-3;
        padding-right: 1rem;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          padding-right: 0px;
          display: none;
        }
      }
      button {
        @include outline-medium-button;
        @include breakpoint("max-sm") {
          padding: 0 20px;
          display: none;
        }
      }
      .trasnformingBox {
        background-color: map-get($colors, backgound-2);
        padding: 80px 80px 160px 80px;
        @include breakpoint("max-sm") {
          padding: 30px 30px 60px 30px;
        }
        h1 {
          @include heading-1;
          color: map-get($colors, black-3);
          margin: 0 0 80px 0;
          @include breakpoint("max-sm") {
            margin: 0 0 30px 0;
          }
        }
        h2 {
          @include heading-3;
          color: map-get($colors, black-3);
          margin: 0 0 20px 0;
        }

        p {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 2px;
        }
      }
    }
  }
}

.transformingImage {
  padding-right: 80px;
  margin-top: -87px;
  @include breakpoint("max-sm") {
    margin-top: -30px;
    padding-right: 0px;
  }
  img {
    width: 100%;
    height: 470px;
    object-fit: cover;
    @include breakpoint("max-md") {
      width: 100%;
      height: 370px;
    }
    @include breakpoint("max-sm") {
      width: 100%;
      height: 300px;
    }
  }
}
.transformingImageleftAlign {
  padding-left: 80px;
  margin-top: -87px;
  @include breakpoint("max-sm") {
    margin-top: -30px;
    padding-right: 0px;
    padding-left: 0px !important;
  }
  img {
    width: 100%;
    height: 470px;
    object-fit: cover;
    @include breakpoint("max-md") {
      height: 370px;
    }
    @include breakpoint("max-sm") {
      height: 300px;
    }
  }
}

.communtiesAlignment {
  padding: map-get($pixel, md) * 16 0;
  @include breakpoint("max-md") {
    padding: 0 0 60px 0;
  }
  @include breakpoint("max-sm") {
    padding: 0 0 80px 0;
  }
}

.communitiesMobileViewAlignment {
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 8 0 0 0;
  }
}
.belowalignment {
  padding-bottom: 160px;
  @include breakpoint("max-sm") {
    padding: 0;
  }
}
.communityBannerMobileHidden {
  @include breakpoint("max-sm") {
    display: none;
  }
}
.communityBannerMobileShow {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
}

.communtiesMobileAlign {
  @include breakpoint("max-sm") {
    padding: 80px 0 0 0;
  }
}

.mobileTextShow {
  padding: 47px 30px 0 30px;
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
  h3 {
    @include heading-3;
    padding-right: 1rem;
    color: map-get($colors, black-3);
    margin: 0 0 map-get($pixel, md) * 2 0;
    @include breakpoint("max-sm") {
      padding-right: 0px;
    }
  }
  button {
    @include outline-medium-button;
    @include breakpoint("max-sm") {
      padding: 0 20px;
    }
  }
}

.mobileTextShowLast {
  @include breakpoint("max-sm") {
    margin: 0 0 80px 0;
  }
}
.underlineAlignment {
  // text-decoration: underline;
  border-bottom: 1px solid #333b49;
  cursor: pointer;
}
