@use 'sass:map';

$bp: (
  base: 0px,
  sm: 540px,
  md: 992px,
  lg: 1280px,
);

@mixin breakpoint($point) {
  @if str-index($point, "max-") == 1 {
    @media screen and (max-width: map.get($bp, str-slice($point, 5))) {
      @content;
    }
  } @else {
    @media screen and (min-width: map.get($bp, $point)) {
      @content;
    }
  }
}
