@import '../../styles/mixins/global.scss';
@import '../../styles/mixins/variable.scss';

.DisclaimerAlignment {
    padding: 104px 0 160px 0;
    @include breakpoint("max-sm") {
        padding: 41px 0 0 0;
    }
    .boxCenterAlignment {
        @include flex-center;
        justify-content: map-get($layout, center );
        .boxWidth {
            width: min(100% , 725px);
            @include breakpoint("max-sm") {
                width: 100%;
            }
            .pageTitle {
                padding: 0 0 map-get($pixel, md ) * 5 + 6px 0;
                @include breakpoint("max-sm") {
                    padding: 0 0 40px 0;
                }
                h1 {
                    @include heading-large;
                    color: map-get($colors, black-3 );
                    margin: 0;
                    @include breakpoint("max-sm") {
                        @include heading-2;
                    }
                }
            }
            .definitionsText {
                h2 {
                    @include heading-medium;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p:last-child {
                    margin: 0;
                    padding-left: 24px;
                }
            }
            .nameAddText {
                padding: 60px 0 88px 0;
                h2 {
                    @include heading-medium;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p:last-child {
                    margin: 0;
                }
            }
            .commonText {
                padding: 0 0 88px 0;
                h2 {
                    @include heading-medium;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
                br {
                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p:last-child {
                    margin: 0;
                }
            }
            .closeButtonStyle {
                padding: 0 0 map-get($pixel, md ) * 16 0;
                button {
                    background: #4F5E79;
                    border-radius: 9999px;
                    padding: 13px 20px;
                    min-width: 148px;
                    height: 44px;
                    border: none;
                    @include text-sm-bold;
                    @include flex-center;
                    cursor: pointer;
                    justify-content: space-between;
                    color: map-get($colors, white-color );
                }
            }
            .lowText {
                padding: 0 0 map-get($pixel, md ) * 4 0;
                p {
                    @include text-md;
                    color: map-get($colors, black-3 );
                    padding-right: 33px;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                        padding: 0;
                    }

                }
                span {
                    text-decoration: underline;
                    font-weight: 700;
                    color: map-get($colors, black-3 )
                }
            }
            .rightTextAlignment {
        
                h3 {
                    @include heading-2;
                    color: map-get($colors, black-3 );
                    font-weight: 700;
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                }
                .listTypeText {
                    padding: 0 0 88px 0;
                    ul {
                        margin: 0;
                        padding: 0;
                        padding-left: 20px;
                        li {
                            @include text-md;
                            color: map-get($colors, black-4 );
                            padding: 0 0 16px 0; 
                            @include breakpoint("max-sm") {
                                font-size: 20px;
                                line-height: 24px;
                            }
                        }
                    }
                }
                h4 {
                    @include heading-4-playfair;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
            .cookiesText {
                padding: 0 0 88px 0;
                h2 {
                    @include heading-medium;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p:last-child {
                    margin: 0;
                }
            }
            .personalDetails {
                padding-left: 24px;
                margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                h3 {
                    @include heading-4-playfair;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 0 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
            .subTextAlignment {
                padding: 0 0 map-get($pixel, md ) * 8 + 8px 0;
                h2 {
                    @include heading-2;
                    font-weight: 700;
                    color: map-get($colors, black-3 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                }
                p {
                    @include text-md;
                    color: map-get($colors, black-4 );
                    margin: 0 0 map-get($pixel,md ) * 2 + 4px 0;
                    @include breakpoint("max-sm") {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                p:last-child {
                    margin: 0;
                }
            }
        }
    }
}