@import "../../styles/mixins/global.scss";

.lrcAboutTitle {
  padding: map-get($pixel, md) * 10 0;
  @include breakpoint("max-md") {
    padding: 60px 0;
  }
  @include breakpoint("max-sm") {
    padding: 60px 0px;
  }
  h1 {
    @include heading-large;
    color: map-get($colors, black-3);
    max-width: 1097px;
    @include breakpoint("max-md") {
      max-width: 100%;
      @include heading-1;
      padding: 0 map-get($pixel, md) * 3;
      max-width: 600px;
    }
    @include breakpoint("max-sm") {
      padding: 0 map-get($pixel, md) * 3;
      @include heading-1;
    }
  }
}

.aboutBanner {
  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include breakpoint("max-md") {
      height: 400px;
    }
    @include breakpoint("max-sm") {
      height: 400px;
    }
  }
}

@include breakpoint("max-md") {
  .mobileViewLrcAboutAlignment {
    padding: 0px;
  }
}
.communityBannerMobileHidden {
  @include breakpoint("max-sm") {
    display: none;
  }
}
.communityBannerMobileShow {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
}
