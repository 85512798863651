@import '../../../styles/mixins/global.scss';


.ExpertiseBannerNewBanner {
    background-image: url("../../../Assets/Images/ExpertiseBanner.png");
    width: 100%;
    background-size: cover;
    height: 500px;
    object-fit: cover;
    display: flex;
    align-items: center;
    h1 {
        @include heading-1;
        color: map-get($colors, white-color );
        max-width: 558px;
    }
}