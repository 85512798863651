@import "../../../styles/mixins/global.scss";

.ourTeamBanner {
  background-color: map-get($colors, backgound-2);
  padding: map-get($pixel, md) * 16 0;
  @include breakpoint("max-md") {
    grid-template-columns: map-get($pixel, md) * 12 0;
    padding: map-get($pixel, md) * 12 0;
  }
  @include breakpoint("max-sm") {
    grid-template-columns: map-get($pixel, md) * 12 0;
    padding: map-get($pixel, md) * 6 0;
  }
  .teamImageAlignment {
    img {
      width: 100%;
      height: 470px;
      object-fit: cover;
      @include breakpoint("max-sm") {
        height: 210px;
      }
    }
  }
  .teamTitle {
    padding: map-get($pixel, md) * 2 0 0 0;
    p {
      @include text;
      color: map-get($colors, light-text-color);
      font-style: italic;
      font-family: "Playfair Display", serif;
      font-weight: 400;
    }
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 426px 426px;
    justify-content: space-between;
    gap: 0 map-get($pixel, md) * 8;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }

    .gridItems {
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        text-transform: uppercase;
        padding-bottom: map-get($pixel, md) * 2;
        @include breakpoint("max-sm") {
          font-size: 18px;
          font-family: "Playfair Display", serif;
          line-height: 23px;
        }
      }
      h1 {
        max-width: 426px;
        padding-right: 10px;
        margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          max-width: 100%;
        }
      }
      h1,
      h2 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0;
      }
      h3 {
        @include text-sm-bold;
        font-weight: 500;

        color: map-get($colors, black-3);
        width: min(100%, 426px);
        @include breakpoint("max-sm") {
          width: 100%;
          @include heading-3;
          text-transform: inherit;
        }
      }
      p {
        @include text-sm-bold;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 2 0;
        @include breakpoint("max-sm") {
          font-family: "Inter";
          text-transform: inherit;
        }
      }
      p:last-child {
        margin: 0;
      }
    }
  }
}
