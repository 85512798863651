@import "../../styles/mixins/global.scss";

.heroBanner {
  background-image: url("../../Assets/Images/new-hero-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: map-get($layout, w-full);
  min-height: map-get($layout, h-screen);
  position: map-get($layout, relative);
  @include breakpoint("max-sm") {
    padding-bottom: 52px;
    min-height: map-get($layout, h-screen);
  }
  .heroTextStyleAlignment {
    padding: map-get($pixel, xl) * 9 + map-get($pixel, md) 0 0 0;
    @include breakpoint("max-sm") {
      padding: map-get($pixel, md) * 16 + 2px 0 0 0;
    }
    .subGrid {
      @include grid-layout;
      grid-template-columns: minmax(0, 1fr) 426px;
      gap: 0 190px;
      position: map-get($layout, relative);
      justify-content: space-between;
      .subGridItems {
        p {
          @include heading-4;
          font-size: 20px;
          margin: 0 0 map-get($pixel, md) * 3 0;
          line-height: 30px;
          color: rgba(255, 255, 255, 0.8);
          @include breakpoint("max-sm") {
            @include heading-4;
            margin-bottom: 139px;
          }
        }
        .buttonAlignment {
          @include flex-center;
          @include breakpoint("max-sm") {
            display: block;
          }
          button {
            padding: map-get($pixel, sm) map-get($pixel, sm) + 4px;
            min-width: 158px;
            border-radius: map-get($pixel, md) * 2 + map-get($pixel, sm);
            @include text-sm-bold;
            height: map-get($pixel, md) * 4 + 4px;
            cursor: map-get($layout, pointer);
            @include flex-center;
            justify-content: center;
            @include breakpoint("max-sm") {
              min-width: 100%;
              justify-content: space-between;
              padding: 5px 20px;
            }
            span {
              padding-right: map-get($pixel, md) - 2px;
            }
          }
          button:first-child {
            background-color: map-get($colors, white-color);
            color: map-get($colors, black-3);
            border: none;
            margin-right: map-get($pixel, md) - 2px;
            @include breakpoint("max-sm") {
              margin-right: 0px;
              margin-bottom: 10px;
            }
          }
          button:last-child {
            border: 1px solid map-get($colors, white-color);
            background-color: transparent;
            color: map-get($colors, white-color);
          }
        }
      }
    }
    .grid {
      @include grid-layout;
      grid-template-columns: minmax(0, 1fr) 539px;
      gap: 0 190px;
      position: map-get($layout, relative);
      justify-content: space-between;
      @include breakpoint("max-md") {
        grid-template-columns: repeat(1, 1fr);
        gap: map-get($pixel, md) * 8 0;
      }
      @include breakpoint("max-sm") {
        gap: 40px 0;
      }
      .gridItems {
        h1 {
          color: map-get($colors, white-color);
          margin: 0;
          @include heading-large;
          font-size: 100px;
          line-height: 120px;
          @include breakpoint("max-sm") {
            @include heading-6;
          }
        }
      }
    }
  }
}

.heroBanner::before {
  position: map-get($layout, absolute);
  top: map-get($pixel, null);
  left: map-get($pixel, null);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  content: "";
  width: map-get($layout, w-full);
  height: map-get($layout, h-full);
}
