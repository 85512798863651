@import "../../../styles/mixins/global.scss";

.NewsCardGrid {
  @include grid-layout;
  padding: 0 0 map-get($pixel, md) * 5 + 1px 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 76px 24px;
  @include breakpoint("max-md") {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint("max-sm") {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 0;
  }
  .NewsCardGridItems {
    .cardImage {
      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        object-position: top;
      }
    }
    .cardDetails {
      padding: map-get($pixel, sm) * 3 0 0 0;
      h2 {
        @include heading-2;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, sm) * 3 + 1px 0;
        cursor: pointer;
      }
      p {
        @include text-sm;

        color: map-get($colors, black-4);
        margin: 0;
        padding: 0 0 map-get($pixel, sm) * 3 + 1px 0;
        border-bottom: 1px solid #ededed;
      }
      span {
        @include text;
        color: map-get($colors, black-4);
        display: block;
        padding: map-get($pixel, sm) * 3 + 1px 0 0 0;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
