@import "../../../styles/mixins/global.scss";

.FounderSectionAlignment {
  user-select: none;
  // padding-left: max(80px, calc((100vw - 1140px) / 2));
  margin: map-get($pixel, md) * 16 0;
  @include breakpoint("max-md") {
    padding-left: map-get($pixel, md) * 2;
    margin: map-get($pixel, md) * 12 0;
  }
  @include breakpoint("max-sm") {
    padding: 0 map-get($pixel, md) * 3;
    margin: map-get($pixel, md) * 8 - map-get($pixel, md) * 8 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 314px 1fr;
    overflow-x: hidden;
    gap: 0 map-get($pixel, md) * 8;
    justify-content: space-between;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
    }
    @include breakpoint("max-sm") {
      padding: 0 map-get($pixel, md) * 3;
      margin: map-get($pixel, md) * 8 0;
      display: none;
    }
    .grid {
      @include grid-layout;
      grid-template-columns: 314px 1fr;
      overflow-x: hidden;
      gap: 0 map-get($pixel, md) * 8;
      justify-content: space-between;

      @include breakpoint("max-sm") {
        display: none;
      }
      .gridItems {
        .sliderArrow {
          @include flex-center;
          @include breakpoint("max-sm") {
            display: none;
          }
          .sliderButton {
            border: 1px solid map-get($colors, gray-1);
            width: map-get($pixel, md) * 6;
            height: map-get($pixel, md) * 6;
            border-radius: 50%;
            @include flex-center;
            justify-content: center;
            cursor: map-get($layout, pointer);
            margin-right: map-get($pixel, sm) + 3px;
          }
        }
        @include breakpoint("max-sm") {
          .sliderView .slideImage {
            display: none;
          }
          .sliderView .slideImage:first-child {
            display: block;
          }
        }
        .sliderView {
          display: map-get($layout, flex);
          .slideImage:first-child {
            img {
              min-width: 367px;
              width: 367px;
              height: 466px;
              @include breakpoint("max-sm") {
                height: 370px;
                width: 315px;
                min-width: 315px;
              }
            }
          }
          .slideImage {
            margin-right: map-get($pixel, md) * 8;
            min-width: 315px;
            width: 315px;
            @include breakpoint("max-sm") {
              width: 315px;
              margin-right: 30px;
              min-width: 315px;
            }
            img {
              width: 100%;
              height: 400px;
            }
          }
        }
        a {
          @include text-md;
          color: map-get($colors, blue-text-color);
          font-family: "Playfair Display", serif;
        }
        h1 {
          @include heading-1;
          color: map-get($colors, black-3);
          margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 4 0;
        }
        p {
          @include text;
          color: map-get($colors, light-text-color);
          margin: 0 0 map-get($pixel, md) * 8 0;
          line-height: map-get($pixel, md) * 2 + 4px;
          padding-right: 10px;
          @include breakpoint("max-sm") {
            margin: 0;
            padding-bottom: 0;
          }
        }
      }

      .sliderButton {
        border: 1px solid map-get($colors, gray-1);
        width: map-get($pixel, md) * 6;
        height: map-get($pixel, md) * 6;
        border-radius: 50%;
        @include flex-center;
        justify-content: center;
        cursor: map-get($layout, pointer);
        margin-right: map-get($pixel, sm) + 3px;
      }
    }
    @include breakpoint("max-sm") {
      .sliderView .slideImage {
        display: none;
      }
      .sliderView .slideImage:first-child {
        display: block;
      }
    }

    .sliderView {
      display: map-get($layout, flex);
      .slideImage:first-child {
        img {
          min-width: 367px;
          width: 367px;
          height: 466px;
          @include breakpoint("max-sm") {
            height: 370px;
            width: 315px;
            min-width: 315px;
          }
        }
      }
      .slideImage {
        margin-right: map-get($pixel, md) * 8;
        min-width: 315px;
        width: 315px;
        @include breakpoint("max-sm") {
          width: 315px;
          margin-right: 30px;
          min-width: 315px;
        }
        img {
          width: 100%;
          height: 400px;
        }
      }
    }
    a {
      @include text-md;
      text-transform: uppercase;
      color: map-get($colors, blue-text-color);
      font-family: "Playfair Display", serif;
    }
    h1 {
      @include heading-1;
      font-size: 37px;
      color: map-get($colors, black-3);
      margin: map-get($pixel, md) * 2 0 map-get($pixel, md) * 4 0;
    }
    span {
      @include text;
      color: map-get($colors, black-3);
      font-weight: 500;
      cursor: pointer;
      display: block;
      padding: map-get($pixel, md) * 2 0 map-get($pixel, md) * 8 0;
    }
    .textHidden {
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 168px;
      transition: 0.3s ease-in-out;
    }
    .textShow {
      max-height: 100vh;
      transition: 0.3s ease-in-out;
    }
    p {
      @include text;
      color: map-get($colors, light-text-color);
      line-height: map-get($pixel, md) * 2 + 4px;
      padding-right: 10px;
      @include breakpoint("max-sm") {
        margin: 0;
        padding-bottom: 0;
      }
    }
  }
}

.benefitsScaleBaner {
  background-image: url("../../../Assets/Images/banner.png");
  background-size: cover;
  width: map-get($layout, w-full);
  height: 500px;
  background-repeat: no-repeat;
  position: map-get($layout, relative);
  @include flex-center;
  @include breakpoint("max-sm") {
    height: 367px;
  }
  .bannerText {
    max-width: 558px;
    height: 100%;
    @include breakpoint("max-sm") {
      max-width: 100%;
    }
    h1 {
      @include heading-1;
      color: map-get($colors, white-color);
      line-height: map-get($pixel, md) * 5 + 2px;
      position: relative;
      font-weight: 400;

      @include breakpoint("max-sm") {
        @include heading-3;
      }
    }
  }
}

.benefitsScaleBaner::before {
  position: absolute;
  top: 0;
  left: 0;
  width: map-get($layout, w-full);
  height: map-get($layout, h-full);
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.dedicatedAlignment {
  padding: 0 0 map-get($pixel, md) * 16 0;
  @include breakpoint("max-md") {
    padding: 0 0 map-get($pixel, md) * 8 0;
  }
  .textGrid {
    @include grid-layout;
    grid-template-columns: 478px 1fr;
    gap: 0 map-get($pixel, md) * 8;
    justify-content: space-between;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 6 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: map-get($pixel, md) * 4 0;
    }

    .textGridItems {
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0;
        @include breakpoint("max-md") {
          max-width: 600px;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        line-height: map-get($pixel, md) * 2 + 2px;
      }
    }
  }
}

.mobileViewCarousal {
  padding: 80px 0;
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
  .mobileCarousalTextHidden {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 120px;
    transition: 0.3s ease-in-out;
  }
  .mobileCarousalTextShow {
    max-height: 100vh;
    transition: 0.3s ease-in-out;
  }
  .mobileCarousalImage {
    img {
      width: 100%;
      object-fit: cover;
      height: 370px;
    }
  }
  .founderDetailsAlignment {
    padding: 40px 0 0 0;
    a {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      display: block;
      padding: 0 0 20px 0;
    }
    h2 {
      @include heading-3;
      color: map-get($colors, black-3);
      margin: 0 0 40px 0;
    }
    p {
      @include text;
      color: map-get($colors, black-3);
      line-height: 24px;
      margin: 0 0 10px 0;
    }
    span {
      @include text;
      color: map-get($colors, black-3);
      line-height: 24px;
      font-weight: 500;
    }
  }
}
.roundIconMobile {
  width: 60px;
  border: 1px solid #7b8595;
  border-radius: 50%;
  position: absolute;
  top: 60px;
  left: -15px;
  height: 60px;
  z-index: 99999;
  @include flex-center;
  justify-content: center;
}
.roundIconRightMobile {
  width: 60px;
  border: 1px solid #7b8595;
  border-radius: 50%;
  position: absolute;
  top: 60px;
  @include flex-center;
  justify-content: center;
  right: -15px;
  z-index: 99999;
  height: 60px;
}
.sliderArrow {
  @include flex-center;
  @include breakpoint("max-sm") {
    display: none;
  }
  .sliderButton {
    border: 1px solid map-get($colors, gray-1);
    width: map-get($pixel, md) * 6;
    height: map-get($pixel, md) * 6;
    border-radius: 50%;
    @include flex-center;
    justify-content: center;
    cursor: map-get($layout, pointer);
    margin-right: map-get($pixel, sm) + 3px;
  }
}
.newsSectionAlignment {
  // padding: map-get($pixel, md) * 16 0 0 0;
  @include breakpoint("max-sm") {
    padding: 40px 0 0 0;
  }
  .viewMoreButton {
    @include flex-center;
    justify-content: map-get($layout, center);
    padding-bottom: 40px;
    button {
      background-color: map-get($colors, black-1);
      min-width: 124px;
      height: 50px;
      @include flex-center;
      justify-content: center;
      @include text-sm-bold;
      position: relative;
      z-index: 9999;
      color: map-get($colors, white-color);
      cursor: map-get($layout, pointer);
      padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
      border: none;
      border-radius: map-get($pixel, md) * 2 + 4px;
      transition: 0.3s ease-in-out;
      svg {
        margin-left: map-get($pixel, md) - 2px;
      }
      &:hover {
        border: 1px solid map-get($colors, black-1);
        color: map-get($colors, black-1);
        background-color: transparent;
        svg {
          path {
            fill: map-get($colors, black-1);
          }
        }
      }
      @include breakpoint("max-sm") {
        width: 100%;
        justify-content: center;
        padding: 5px 20px;
      }
    }
  }
  .cardGrid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0 map-get($pixel, md) * 8 0;
    gap: 0 map-get($pixel, md) * 2;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
      padding: 0 0 40px 0;
    }
    .cardGridItems {
      .cardDetails {
        padding: map-get($pixel, md) * 2 + 5px 0 0 0;
        h3 {
          @include heading-2;
          color: map-get($colors, black-3);
          margin: 0;
          font-weight: 600;
          padding: 0 0 map-get($pixel, sm) * 3 + 1px 0;
          border-bottom: 1px solid #ededed;
          cursor: pointer;
        }

        p {
          @include text;
          color: map-get($colors, black-4);
          margin: map-get($pixel, md) + 6px 0 0 0;
          a {
            text-decoration: underline;
          }
        }
      }
      .cardImage {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          @include breakpoint("max-sm") {
            height: 260px;
          }
        }
      }
    }
  }

  .pageTitle {
    padding: 0 0 map-get($pixel, md) * 8 0;
    @include breakpoint("max-sm") {
      padding: 0 0 40px 0;
    }
    a {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      text-transform: uppercase;
      display: block;
      padding: 0 0 map-get($pixel, md) * 2 0;
    }
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      margin: 0;
      @include breakpoint("max-sm") {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
