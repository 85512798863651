@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/variable.scss";

.wellbeingEcosystemBanner {
  background-color: map-get($colors, backgound-2);
  padding: map-get($pixel, md) * 19 + 6px 0 map-get($pixel, md) * 16 0;
  @include breakpoint("max-sm") {
    padding: 80px 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 538px;
    gap: 0 map-get($pixel, md) * 13;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    .gridItems {
      .subGrid {
        @include grid-layout;
        grid-template-columns: repeat(2, 1fr);
        gap: map-get($pixel, md) * 6 map-get($pixel, md) * 4;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1, 1fr);
          gap: 60px;
        }
        .subGridItems {
          h2 {
            @include heading-2;
            font-weight: 400;
            color: map-get($colors, black-3);
            margin: 0 0 map-get($pixel, md) * 2 0;
          }
          p {
            @include text;
            color: map-get($colors, black-4);
            padding: 0;
            line-height: 22px;
            letter-spacing: -0.01em;
            @include breakpoint("max-sm") {
              margin: 0;
            }
          }
        }
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 2 0;
        @include breakpoint("max-sm") {
          @include heading-3;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        line-height: 24px;
        padding-right: 20px;
        letter-spacing: -0.01em;
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          margin: 0 0 20px 0;
        }
      }
      h3 {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        margin: 0;
        @include breakpoint("max-sm") {
          display: none;
        }
      }
      h6 {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        margin: 0;
        display: none;
        @include breakpoint("max-sm") {
          display: block;
          margin: 0 0 map-get($pixel, sm) * 4 0;
        }
      }
    }
  }
}
