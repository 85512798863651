@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.DigitalExperienceAlignment {
    .pageTitle {
        padding: 0 0 40px 0;
        h1 {
            @include heading-1;
            color: map-get($colors, black-3 );
            margin: 0;
            @include breakpoint("max-sm") {
                @include heading-3;
            }
        }
    }
    .grid {
        @include grid-layout;
        grid-template-columns: repeat(2 , 1fr);
        gap: 0 20px;
        justify-content: space-between;
        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 0;
        }
        .gridItems:nth-child(1) ,
        .gridItems:nth-child(2)
        {
            border-top: 1px solid map-get($colors, gray-1);
        }
        .gridItems:nth-child(2){
            @include breakpoint("max-sm") {
                border-top: none;
            }
        }
        .gridItems {
            padding: map-get($pixel, md ) * 2 0;
            border-bottom: 1px solid map-get($colors, gray-1);
            span {
                @include text;
                color: map-get($colors, black-3 );
                display: block;
            }
        }
    }
}