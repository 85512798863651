@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/variable.scss';

.TechnolofyIncreaseAlignment {
    padding: map-get($pixel, md ) * 16 0;
    @include breakpoint("max-sm") {
        padding: 80px 0 30px 0;
    }
    .EnhancingTenanstsAlignment {
       
        .grid {
            @include grid-layout;
            grid-template-columns: minmax(0 , 1fr) 650px;
            gap: 0 map-get($pixel, md ) * 8;
            @include breakpoint("max-md") {
                grid-template-columns: repeat(1 , 1fr);
                gap: 60px 0;
            }
            @include breakpoint("max-sm") {
                gap: 40px 0;
            }
            .gridItems {
                .fullImage {
                    img {
                        width: 100%;
                        height: 468px;
                        object-fit: cover;
                        @include breakpoint("max-sm") {
                            height: 226px;
                        }
                    }
                }
                .childImage {
                    img {
                        width: 260px;
                        height: 180px;
                        object-fit: cover;
                        @include breakpoint("max-sm") {
                            width: 208px;
                            height: 144px;
                        }
                    }
                }
                p {
                    @include text;
                    color: map-get($colors,black-4 );
                    line-height: 22px;
                    max-width: 366px;
                    margin: 0 0 map-get($pixel,md ) * 4 + 7px 0;
                    letter-spacing: -0.01em;
                }
            }
        }
        .textGrid {
            @include grid-layout;
            grid-template-columns: 590px minmax(0 , 1fr);
            padding: 0 0 map-get($pixel, md) * 8 0;
            gap: 0 map-get($pixel,md ) * 6;
            @include breakpoint("max-md") {
                grid-template-columns: repeat(1 , 1fr);
            }
            @include breakpoint("max-sm") {
                gap: 20px 0;
                padding: 0 0 20px 0;
            }
            .textGridItems:nth-child(1) {
                @include breakpoint("max-sm") {
                    order: 2;
                }
            }
            .textGridItems {
                h1 {
                    @include heading-1;
                    color: map-get($colors, black-3 );
                    margin: 0;
                    @include breakpoint("max-sm") {
                        @include heading-3;
                    }
                }
                p {
                    @include text-md-playfir;
                    color: map-get($colors,blue-text-color );
                    text-transform: uppercase;
                    text-align: right;
                    margin: 0;
                    @include breakpoint("max-sm") {
                        text-align: left;
                    }
                }
            }
        }
    }
    .techTextGrid {
        @include grid-layout;
        grid-template-columns: 478px minmax(0 , 1fr);
        gap: 0 map-get($pixel,md ) * 8 ;
        padding: 0 0 map-get($pixel, md ) * 16 0;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: 60px 0;
        } 
        @include breakpoint("max-sm") {
            gap: 34px 0;
            padding: 0 0 80px 0;
        } 
        .techTextGridItems {
            h1 {
                @include heading-1;
                color: map-get($colors, black-3 );
                margin: 0;
                @include breakpoint("max-sm") {
                    @include heading-3;
                }
            }
            p {
                @include text;
                color: map-get($colors,black-4 );
                line-height: 22px;
                letter-spacing: -0.01em;
            }
        }
    }   
}