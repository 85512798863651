@import '../../../styles/mixins/global.scss';

.ImpressiveRecordAlignment {
    padding: map-get($pixel, md ) * 16 0;
    @include breakpoint("max-md") {
        padding: map-get($pixel, md ) * 12 0;
    }
    @include breakpoint("max-sm") {
        padding: map-get($pixel, md ) * 8 0 113px 0;
    }
    .textGrid {
        @include grid-layout;
        grid-template-columns: 478px 1fr;
        gap: 0 map-get($pixel, md ) * 8;
        justify-content: space-between;
        padding-bottom: map-get($pixel, md ) * 16;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: map-get($pixel, md ) * 6 0;
            padding: 0 0 80px 0;
        }
        @include breakpoint("max-sm") {
            padding: 0 0 96px 0;
        }
        .textGridItems {
            h1 {
                @include heading-1;
                color: map-get($colors, black-3 );
                margin: 0;
            }
            p {
                @include text;
                color: map-get($colors, black-4 );
                line-height: map-get($pixel, md ) * 2 + 2px;
            }
        }
    }
    .grid {
        @include grid-layout;
        grid-template-columns: 1fr 518px;
        justify-content: space-between;
        gap: 0 map-get($pixel, md ) * 6;
        @include breakpoint("max-md") {
            grid-template-columns: repeat(1 , 1fr);
            gap: map-get($pixel, md ) * 6 0;
        }
        @include breakpoint("max-md") {
            .gridItems:nth-child(1) {
                order: 2;
            }
            .gridItems:nth-child(2) {
                order: 1;
            }
        }
        .gridItems {
            .quesBorderTop {
                border-top: 1px solid map-get($colors, gray-1 );
            }
            .imageStyle {
                img {
                    width: 100%;
                    height: 600px;
                    object-fit: cover;
                    @include breakpoint("max-sm") {
                        height: 319px;
                    }
                }
            }

            .questionMain {
                border-bottom: 1px solid map-get($colors , gray-1 );
                padding: map-get($pixel, md ) * 4 0;

            }
            .textQuesStyle {
                margin: map-get($pixel, md) * 4 0 0 0;
                p {
                    @include text;
                    color: map-get($colors, black-4 );
                    line-height: map-get($pixel, md ) * 2 + 2px;
                }
            }
            .textHidden {
                max-height: 0px;
                overflow: hidden;
                transition: .3s ease-in-out;
            }
            .textShow {
                max-height: calc(100vh - 120px);
                overflow: hidden;
                transition: .3s ease-in-out;
            }
            .quesGrid {
               @include grid-layout;
               align-items: center;
               grid-template-columns: 1fr 15px;
               gap: 0 10px;
               justify-content: space-between;
               .quesGridItems:nth-child(2) {
                @include flex-center;
                cursor: pointer;
            }
               .quesGridItems {
                   h1 {
                       @include heading-1;
                       color: map-get($colors, black-3 );
                       margin: 0;
                       line-height: 48px;
                   }
               }
            }
        }
    }
}