@import "../../styles/mixins/global.scss";
@import "../../styles/mixins/variable.scss";

.WellbeingAlignment {
  padding: map-get($pixel, md) * 10 0 map-get($pixel, md) * 11 + 7px 0;
  @include breakpoint("max-sm") {
    padding: map-get($pixel, md) * 6 0;
  }
  h1 {
    @include heading-large;
    color: map-get($colors, black-3);
    margin: 0;
    width: min(100%, 773px);
    @include breakpoint("max-sm") {
      @include heading-1;
      width: 100%;
    }
  }
}

.WellbeingBanner {
  .bannerDesign {
    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
      @include breakpoint("max-sm") {
        height: 400px;
      }
    }
  }
}
.communityBannerMobileHidden {
  @include breakpoint("max-sm") {
    display: none;
  }
}
.communityBannerMobileShow {
  display: none;
  @include breakpoint("max-sm") {
    display: block;
  }
}
