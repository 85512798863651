@import "../../../styles/mixins/global.scss";
@import "../../../styles/mixins/typography.scss";

.CommunityBlogAlign {
  padding: map-get($pixel, md) * 16 0;
  @include breakpoint("max-sm") {
    padding: 80px 0 0 0;
  }
  .grid {
    @include grid-layout;
    grid-template-columns: 590px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 8;
    align-items: map-get($layout, center);
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 80px 0;
    }
    .gridItems:nth-child(1) {
      @include breakpoint("max-sm") {
        order: 1;
      }
    }
    .gridItems {
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 300px;
        }
      }
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        padding: 0 0 map-get($pixel, md) * 2 0;
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          @include heading-3;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 2 0;
        letter-spacing: -0.01em;
        line-height: 22px;
      }
      p:last-child {
        margin: 0;
      }
    }
  }
  .subGrid {
    @include grid-layout;
    padding: map-get($pixel, md) * 16 0 0 0;
    grid-template-columns: minmax(0, 1fr) 590px;
    gap: 0 map-get($pixel, md) * 8;
    align-items: map-get($layout, center);
    @include breakpoint("max-md") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
    }
    @include breakpoint("max-sm") {
      padding: 80px 0;
    }
    .subGridItems {
      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 300px;
        }
      }
      a {
        @include text-md-playfir;
        color: map-get($colors, blue-text-color);
        display: block;
        padding: 0 0 map-get($pixel, md) * 2 0;
      }
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0 0 map-get($pixel, md) * 4 0;
        @include breakpoint("max-sm") {
          @include heading-3;
          padding-right: 1rem;
        }
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 2 0;
        letter-spacing: -0.01em;
        line-height: 22px;
      }
      p:last-child {
        margin: 0;
      }
    }
  }
}
