@import "../../../styles/mixins/global.scss";

.OfficeInformationAlignment {
  padding: map-get($pixel, md) * 16 0;
  @include breakpoint("max-md") {
    padding: 80px 0;
  }
  @include breakpoint("max-sm") {
    padding: 60px 0;
  }
  .officeText {
    span {
      @include text-md-playfir;
      color: map-get($colors, blue-text-color);
      font-family: "Playfair Display", serif;
      text-transform: uppercase;
      padding-bottom: map-get($pixel, sm) * 3 + 1px;
    }
  }
  .pageTitleAlignment {
    @include flex-center;
    @include breakpoint("max-sm") {
      display: block;
    }
    justify-content: space-between;
    div {
      h1 {
        @include heading-large;
        color: map-get($colors, black-3);
        margin: 0;
        font-style: italic;
        @include breakpoint("max-sm") {
          @include heading-9;
        }
      }
    }
    .sliderMenu {
      @include flex-center;
      @include breakpoint("max-sm") {
        margin: 40px 0 0 0;
      }
      .sliderButton:last-child {
        margin-right: 0px;
      }
      .sliderButton {
        width: map-get($pixel, md) * 6;
        height: map-get($pixel, md) * 6;
        @include flex-center;
        justify-content: center;
        margin-right: map-get($pixel, sm) + 3px;
        border-radius: 50%;
        border: 1px solid map-get($colors, gray-1);
        cursor: map-get($layout, pointer);
      }
    }
  }
  .locationBoxAlignment {
    max-width: 873px;
    .locationGrid {
      @include grid-layout;
      grid-template-columns: 315px 1fr;
      align-items: map-get($layout, center);
      gap: 0 map-get($pixel, md) * 8;
      @include breakpoint("max-sm") {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px 0;
      }
      .locationGridItems {
        img {
          width: 315px;
          height: 315px;
          object-fit: cover;
        }
        h2 {
          @include heading-3;
          color: map-get($colors, black-3);
          font-style: italic;
          margin: 0 0 map-get($pixel, md) * 2 0;
        }
        p {
          @include text;
          color: map-get($colors, black-4);
          line-height: map-get($pixel, md) * 2 + 4px;
          margin: 0 0 map-get($pixel, md) * 2 0;
        }
        a {
          font-family: "Playfair Display", serif;
          font-size: map-get($pixel, xl);
          line-height: map-get($pixel, xl) + 4px;
          font-weight: 500;
          font-style: italic;
        }
      }
    }
  }
  .grid {
    @include grid-layout;
    padding: map-get($pixel, md) * 8 0 map-get($pixel, md) * 8 0;
    grid-template-columns: 202px minmax(0, 1fr);
    gap: 0 map-get($pixel, md) * 8;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 40px 0;
      padding: 60px 0;
    }
    .gridItems {
      img {
        width: 100%;
        height: 470px;
        object-fit: cover;
        @include breakpoint("max-sm") {
          height: 250px;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li:first-child {
          background-color: map-get($colors, black-3);
          color: map-get($colors, white-color);
        }
        li {
          text-decoration: none;
          padding: map-get($pixel, sm) map-get($pixel, sm) * 4;
          height: 47px;
          border-radius: 9999px;
          cursor: map-get($layout, pointer);
          @include text-sm-bold;
          margin-bottom: map-get($pixel, sm) + 3px;
          font-weight: 500;
          color: map-get($colors, black-4);
          border: 1px solid transparent;
          text-align: center;
          @include flex-center;
          transition: 0.3s ease-in-out;
          &:hover {
            background-color: map-get($colors, black-3);
            color: map-get($colors, white-color);
          }
        }
      }
    }
  }
}

.allOfficeLocationShow {
  .officeInfroGrid:last-child {
    border-bottom: none;
    .officeInfroGridItems {
      padding: 40px 0 0 0;
      @include breakpoint("max-sm") {
        padding: 40px 0;
      }
    }
  }
}

.officeInforAlignment {
  padding: map-get($pixel, md) * 12 0;
  @include breakpoint("max-sm") {
    padding: 80px 0;
  }
  .officeInfroGrid {
    @include grid-layout;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 48px;
    border-bottom: 1px solid #c0c8d6;
    @include breakpoint("max-sm") {
      border: none;
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    .officeInfroGridItems {
      padding: map-get($pixel, md) * 4 0;
      @include breakpoint("max-sm") {
        border-bottom: 1px solid #c0c8d6;
      }
      .textSubGrid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 295px;
        gap: 0 10px;
        justify-content: space-between;
        @include breakpoint("max-sm") {
          grid-template-columns: repeat(1, 1fr);
        }
        .textSubGridItems {
          h2 {
            @include heading-2;
            font-weight: 400;
            color: map-get($colors, black-3);
            @include breakpoint("max-sm") {
              margin: 0 0 map-get($pixel, md) * 2 0;
            }
          }
          span {
            @include heading-4;
            font-weight: 500;
            color: map-get($colors, blue-text-color);
            display: block;
            @include breakpoint("max-sm") {
              @include text;
              color: map-get($colors, blue-text-color);
            }
          }
          p {
            @include text;
            color: map-get($colors, black-3);
            padding-right: 20px;
            line-height: 24px;
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }
  .contactTextGrid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 426px;
    gap: 0 319px;
    padding: 0 0 map-get($pixel, md) * 8 0;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
      padding: 0 0 70px 0;
    }
    .contactTextGridItems {
      h1 {
        @include heading-1;
        color: map-get($colors, black-3);
        margin: 0;
        @include breakpoint("max-sm") {
          @include heading-3;
          font-weight: 400;
        }
      }
      p {
        @include text;
        padding-right: 47px;
        color: map-get($colors, black-4);
        line-height: 25px;
        margin: 0;
        @include breakpoint("max-sm") {
          padding-right: 7px;
        }
      }
    }
  }
}
