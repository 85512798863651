@import "../../styles/mixins/global.scss";

.UrbanRegenrationPageTitle {
  padding: map-get($pixel, xl) * 5 0 map-get($pixel, xl) * 6 0;
  @include breakpoint("max-md") {
    padding: 60px 0 60px 0;
  }
  .pageTitle {
    h1 {
      @include heading-large;
      color: map-get($colors, black-3);
      max-width: 975px;
      @include breakpoint("max-md") {
        max-width: 100%;
        max-width: 100%;
        @include heading-1;
      }
      @include breakpoint("max-sm") {
        @include heading-9;
        margin: 0 0 0px 0;
      }
    }
    div {
      @include flex-center;
      padding: 17px 0 0 0;
      justify-content: flex-end;
      @include breakpoint("max-sm") {
        padding: 0;
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        max-width: 448px;
        line-height: map-get($pixel, md) * 2 + 2px;
        @include breakpoint("max-md") {
          margin: 40px 0 0 0;
        }
        @include breakpoint("max-sm") {
          margin: 0 0 0 0;
        }
      }
    }
  }
}

.UrbanRegenrationBanner {
  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    @include breakpoint("max-md") {
      height: 400px;
    }
  }
}

.urbanSpaceAlignment {
  @include breakpoint("max-sm") {
    padding: 0;
  }
}
