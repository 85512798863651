@import "../../../styles/mixins/global.scss";

.hospitalityExperienceBanner {
  background-color: map-get($colors, backgound-2);
  padding: map-get($pixel, xl) * 8 0;
  @include breakpoint("max-sm") {
    padding: 80px 0 40px 0;
  }
  .expertiseTextGrid {
    @include grid-layout;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 0;
    }
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
    .expertiseTextGridItems:nth-child(1) {
      padding-left: 0px;
    }
    .expertiseTextGridItems:nth-child(3) {
      border: none;
      padding-right: 0px;
      @include breakpoint("max-md") {
        padding-left: 0px;
      }
      @include breakpoint("max-sm") {
        border-bottom: none;
        padding-bottom: 20px;
      }
    }
    .expertiseTextGridItems {
      padding: 0 map-get($pixel, md) * 4;
      border-right: 1px solid map-get($colors, black-4);
      @include breakpoint("max-sm") {
        padding: 40px 0;
        border-right: none;
        border-bottom: 1px solid map-get($colors, black-4);
      }
      h2 {
        @include heading-2;
        color: map-get($colors, black-3);
        font-weight: 400 !important;
        margin: 0 0 map-get($pixel, sm) * 3 0;
      }
      p {
        @include text;
        color: map-get($colors, black-4);
        margin: 0 0 map-get($pixel, md) * 3 + 2px 0;
        line-height: 22px;
      }
      button {
        min-width: 146px;
        height: 50px;
        border: 1px solid map-get($colors, black-1);
        background-color: map-get($colors, white-color);
        padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
        @include text-sm-bold;
        color: map-get($colors, black-1);
        border-radius: map-get($pixel, md) * 2 + 4px;
        background-color: transparent;
        @include flex-center;
        justify-content: center;
        cursor: map-get($layout, pointer);
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: map-get($colors, black-1);
          color: map-get($colors, white-color);
          svg {
            path {
              fill: map-get($colors, white-color);
            }
          }
        }
        @include breakpoint("max-sm") {
          width: 146px;
          padding: 5px 20px;
          justify-content: space-between;
        }
        span {
          padding-right: map-get($pixel, md) - 2px;
        }
      }
    }
  }
  .newPageTitle {
    padding: 0 0 map-get($pixel, md) * 8 0;
    @include breakpoint("max-sm") {
      padding: 0 0 0 0;
    }
    a {
      @include text-md-playfir;
      display: block;
      padding: 0 0 map-get($pixel, sm) + 3px 0;
      color: map-get($colors, blue-text-color);
      text-transform: uppercase;
    }
    h1 {
      @include heading-1;
      color: map-get($colors, black-3);
      @include breakpoint("max-sm") {
        font-size: 30px;
        line-height: 40px;
        margin: 12px;
      }
    }
  }
  .leftAlignmentContent {
    padding-left: max(80px, calc((100vw - 1120px) / 2));
    @include breakpoint("max-sm") {
      padding-left: max(30px, calc((100vw - 1120px) / 2));
    }

    .imageAlignment {
      div {
        @include breakpoint("max-md") {
          width: calc(100% - 150px);
          min-width: calc(100% - 150px);
        }
        @include breakpoint("max-sm") {
          margin-right: 30px;
          width: calc(100% - 0px);
          min-width: calc(100% - 0px);
        }
      }
      img {
        height: 500px;
        object-fit: cover;
        width: 985px;
        @include breakpoint("max-md") {
          width: 100%;
          height: 350px;
        }
        @include breakpoint("max-sm") {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
  .secCardAlignment {
    padding-top: map-get($pixel, xl) * 4;
    @include breakpoint("max-sm") {
      padding-top: 40px;
    }
  }
  .galleryTextGrid {
    @include grid-layout;
    grid-template-columns: 1fr;
    gap: 0 132px;
    padding: map-get($pixel, xl) * 8 0 map-get($pixel, md) * 4 + 8px 0;
    justify-content: space-between;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
      padding: 60px 0 38px 0;
    }
    .galleryTextGridItems {
      a {
        @include text-md;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        font-family: "Playfair Display", serif;
        display: block;
      }
      h1 {
        @include heading-1;
        width: 762px;
        color: map-get($colors, black-1);
        margin: 0;
        @include breakpoint("max-md") {
          max-width: 100%;
          width: 100%;
        }
        @include breakpoint("max-sm") {
          font-size: 30px;
          line-height: 40px;
        }
      }
    }
  }
  .gridAlignment {
    .grid:last-child {
      padding-bottom: 0px;
      border-bottom: none;
    }
    .grid {
      @include grid-layout;
      grid-template-columns: 203px minmax(0, 1fr);
      gap: 0 132px;
      padding-bottom: map-get($pixel, xl) * 4;
      border-bottom: 1px solid map-get($colors, border-color-2);
      @include breakpoint("max-md") {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px 0;
      }
      @include breakpoint("max-sm") {
        padding-bottom: 40px;
        gap: 36px 0;
      }
      .gridItems {
        h3 {
          @include text;
          color: map-get($colors, black-4);
          margin-top: 40px;
          line-height: map-get($pixel, md) * 2 + 2px;
          @include breakpoint("max-sm") {
            margin-top: 0px;
            max-width: 203px;
          }
        }
        .cardImage {
          img {
            width: map-get($layout, w-full);
            height: 470px;
            object-fit: cover;
            @include breakpoint("max-sm") {
              height: 200px;
            }
          }
        }
        .cardDetails {
          padding: map-get($pixel, md) * 5 + 5px 0 0 0;
          @include breakpoint("max-sm") {
            padding-top: 38px;
          }
          button {
            min-width: 146px;
            height: 50px;
            border: 1px solid map-get($colors, black-1);
            background-color: map-get($colors, white-color);
            padding: map-get($pixel, sm) map-get($pixel, sm) + 3px;
            @include text-sm-bold;
            color: map-get($colors, black-1);
            border-radius: map-get($pixel, md) * 2 + 4px;
            background-color: transparent;
            @include flex-center;
            justify-content: center;
            cursor: map-get($layout, pointer);
            transition: 0.3s ease-in-out;
            &:hover {
              background-color: map-get($colors, black-1);
              color: map-get($colors, white-color);
              svg {
                path {
                  fill: map-get($colors, white-color);
                }
              }
            }
            @include breakpoint("max-sm") {
              width: 100%;
              padding: 5px 20px;
              justify-content: space-between;
            }
            span {
              padding-right: map-get($pixel, md) - 2px;
            }
          }
          .cardDetailsGrid {
            @include grid-layout;
            grid-template-columns: 1fr 90px;
            padding-bottom: map-get($pixel, md) * 7 + 7px;
            gap: 0 132px;
            justify-content: space-between;
            @include breakpoint("max-md") {
              grid-template-columns: repeat(1, 1fr);
              gap: 40px 0;
            }
            @include breakpoint("max-sm") {
              padding-bottom: 70px;
            }
            .cardDetailsGridItems {
              h1 {
                @include heading-3;
                color: map-get($colors, black-1);
                margin: 0 0 map-get($pixel, md) * 2 0;
                @include breakpoint("max-sm") {
                  @include heading-5;
                  font-family: "Playfair Display", serif;
                }
              }
              p {
                @include text;
                color: map-get($colors, black-4);
                line-height: map-get($pixel, md) * 2 + 2px;
              }
            }
          }
        }
      }
    }
  }
  .pageTitleGrid {
    @include grid-layout;
    grid-template-columns: minmax(0, 1fr) 203px;
    gap: 0 132px;
    padding-bottom: 138px;
    justify-content: space-between;
    @include breakpoint("max-sm") {
      grid-template-columns: repeat(1, 1fr);
      gap: 60px 0;
      padding-bottom: 60px;
    }
    @include breakpoint("max-sm") {
      .pageTitleGridItems:nth-child(1) {
        order: 2;
      }
    }
    .pageTitleGridItems {
      h1 {
        @include heading-1;
        margin: 0;
        color: map-get($colors, black-1);
        @include breakpoint("max-sm") {
          @include heading-6;
        }
      }
      a {
        @include text-md;
        color: map-get($colors, blue-text-color);
        text-transform: uppercase;
        font-family: "Playfair Display", serif;
        display: block;
        text-align: map-get($layout, right);
        @include breakpoint("max-sm") {
          text-align: left;
        }
      }
    }
  }
}

.rightImageAlignment {
  padding-right: 80px;
  @include breakpoint("max-sm") {
    padding-right: 20px;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 500px;
    @include breakpoint("max-sm") {
      height: 200px;
      object-fit: cover;
    }
  }
}
.sliderArrow {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #7b8595;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  align-items: center;
}
.sliderLeftArrow {
  top: -90px;
  right: 157px;
  position: absolute;
}
.sliderRightArrow {
  top: -90px;
  right: 228px;
  position: absolute;
}
